import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Arrow from "../assets/images/arrow.svg";
import defaultProfessional from "../assets/images/defaultProfile.png";
import { saveUserDetailsAction } from "../actions/userActions";
import { serviceRetrieveAction, serviceLastBookingAction } from "../actions/serviceActions";
import Loader from "./Loader/Loader";
import Header from "./Header";
import InputMask from "react-input-mask";
import { teamMembersRetrieveAction, saveProfessionalAction, TeamNameAction } from "../actions/teamActions";
import { AsapBookingAction, EmptyAsapBookingAction, searchBookingRetrieveActionEmpty } from "../actions/bookingActions";
const ChooseService = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [userDetails, setUserDetails] = useState({
		userName: "",
		mobileNo: "",
		mobileNoMask: "",
	});
	const [userDetailsError, setUserDetailsError] = useState({
		userNameError: true,
		userMobileNoError: true,
	});
	const [hideBooking, setHideBooking] = useState(false);
	const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(0);
	const [activeCategoryId, setActiveCategoryId] = useState();
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedServices, setSelectedServices] = useState([]);
	const mobileNoPattern = /^([+]\d{1})?\d{10}$/;
	const nospecial = /^[^*|\"_:<>[\]{}`\\()';@&$!#+-/.,|?<>~1236456790%^=]+$/;
	const teamMembersList = useSelector((state) => state.teamMembersDetails);
	const { teamMembers } = teamMembersList;
	const locationList = useSelector((state) => state.savedLocation);
	const { selectedLocation } = locationList;
	const servicesDetails = useSelector((state) => state.serviceDetails);
	const lastBooking = useSelector((state) => state.lastBooking);
	const lastBookingService = lastBooking.servicesList;
	let typedDetails = useSelector((state) => state.userDetails.user?.userInfo);
	const asapBookingStore = useSelector((state) => state.asapBooking);
	const { servicesList, loading, error } = servicesDetails;
	let servicesData = servicesList ? servicesList?.filter((service) => service.type === "ITEM") : "";
	sessionStorage.setItem("servicesList", JSON.stringify(servicesData));
	const [stateFromState, setStateFromState] = useState(JSON.parse(sessionStorage.getItem("servicesList")));
	const [services, setServices] = useState(servicesData);
	const categories = servicesList ? servicesList?.filter((service) => service.type === "CATEGORY") : "";
	const [categoriesData, setCategoriesData] = useState(categories);

	let newHair = categories.filter((values) => values.categoryData.name.toLowerCase() === "haircuts" || "hair");

	const [view, setView] = useState(false);
	const [isTouched, setIstouched] = useState(false);
	const [isTouchedAsap, setIstouchedAsap] = useState(false);
	const [lastBookingDetails, setLastBookingDetails] = useState({
		bookings: "",
		teamMember: "",
		bookingFullData: "",
	});
	const [asapTriggered, setAsapTriggered] = useState(false);
	const [hairId, setHairId] = useState("");

	const teamMemberName = useSelector((state) => state.teamname.professionalName.teamMember);

	const getAllSelectedTeamMembers = (selectedTeamMembers) => {
		let tempArr = [];
		let objServiceId = {};
		selectedTeamMembers.forEach((values) => {
			if (
				values.hasOwnProperty("itemData") &&
				values.itemData &&
				values.itemData.hasOwnProperty("variations") &&
				values.itemData.variations &&
				values.itemData.variations.length
			) {
				values.itemData.variations.forEach((variations) => {
					if (
						variations.hasOwnProperty("itemVariationData") &&
						variations.itemVariationData &&
						variations.itemVariationData.hasOwnProperty("teamMemberIds") &&
						variations.itemVariationData.teamMemberIds &&
						variations.itemVariationData.teamMemberIds.length
					) {
						let variationIdKey = variations.id;
						objServiceId[variationIdKey] = variations.itemVariationData.teamMemberIds;
						let tempMemberArrConcat = tempArr.concat(variations.itemVariationData.teamMemberIds);
						tempArr.push(...tempMemberArrConcat);
					}
				});
			}
		});
		return objServiceId;
	};

	const getAllSelectedTeamMembersLastBooking = (services) => {
		let objServiceId = {};
		services.forEach((values) => {
			let serviceId = values.serviceVariationId;
			objServiceId[serviceId] = [values.teamMemberId];
		});
		return objServiceId;
	};

	useEffect(() => {
		if (lastBooking && lastBooking.hasOwnProperty("servicesList") && lastBookingService && lastBookingService.length) {
			dispatch(
				teamMembersRetrieveAction({
					locationId: selectedLocation.id,
					serviceVariationId: getAllSelectedTeamMembersLastBooking(lastBooking.servicesList[0].appointmentSegments),
				})
			);
			dispatch(TeamNameAction(lastBooking.servicesList[0].appointmentSegments[0].teamMemberId));
			setStateFromState(JSON.parse(sessionStorage.getItem("servicesList")));
		}
	}, [lastBooking]);

	useEffect(() => {
		let fetchSelectedServiceData = JSON.parse(sessionStorage.getItem("services"));
		let selectedCategory = JSON.parse(sessionStorage.getItem("selectedCategory"));
		setHairId(newHair);
		if (selectedCategory && selectedCategory.toString().length) {
			setActiveCategoryId(selectedCategory);
		} else {
			if (servicesList) {
				sessionStorage.setItem("selectedCategory", JSON.stringify(categories.length !== 0 ? categories[0].id : ""));
				setActiveCategoryId(categories.length !== 0 ? categories[0].id : "");
				servicesList.forEach((element) => {
					element.checkedFlag = false;
				});
				servicesData = servicesList ? servicesList?.filter((service) => service.type === "ITEM") : "";
				setServices(servicesData);
			}
		}
		if (fetchSelectedServiceData && fetchSelectedServiceData.length > 0) {
			let newServices = [];
			let tempArr = [];
			setTotalAmount([]);
			setSelectedServices([]);
			setTotalSelectedCheckboxes(0);
			fetchSelectedServiceData.forEach((element) => {
				newServices.push(element);
				if (element.itemData.variations[0].itemVariationData.hasOwnProperty("priceMoney") && element.checkedFlag === true) {
					tempArr.push(element);
					let amount = Number(element.itemData.variations[0].itemVariationData.priceMoney.amount.replace("n", "") / 100);
					setTotalSelectedCheckboxes((totalSelectedCheckboxes) => totalSelectedCheckboxes + Number(1));
					setTotalAmount((totalAmount) => {
						totalAmount = Number(totalAmount) + Number(amount);
						return totalAmount;
					});
				}
			});
			setSelectedServices(tempArr);
			setServices(newServices);
			setIstouched(true);
		}
	}, [servicesList]);

	useEffect(() => {
		setIstouchedAsap(false);
		dispatch(searchBookingRetrieveActionEmpty());
	}, []);

	useEffect(() => {
		sessionStorage.removeItem("lastBooking");
		setLastBookingDetails({
			bookings: "",
			teamMember: "",
			bookingFullData: "",
		});
		if (
			lastBookingService &&
			lastBookingService.length &&
			lastBookingService[0] &&
			lastBookingService[0].appointmentSegments.length &&
			stateFromState &&
			stateFromState.length &&
			teamMembers &&
			teamMembers.length
		) {
			let tempArrSevice = [];
			let tempArr = [];
			lastBookingService[0].appointmentSegments.forEach((element) => {
				stateFromState.forEach((service) => {
					delete service.checkedFlag;
					service.itemData.variations.forEach((variationsData, index) => {
						if (service.itemData.variations[index].id === element.serviceVariationId) {
							tempArrSevice.push(service);
							tempArr.push(service.itemData);
						}
					});
				});
				var FilterTeamMember = teamMembers.filter((team) => (team.teamMemberId ? team.teamMemberId : team.teamMember === element.teamMemberId));
				setHideBooking(true);
				setLastBookingDetails({
					bookings: tempArr,
					teamMember: FilterTeamMember[0],
					bookingFullData: tempArrSevice,
				});
			});
			if (tempArrSevice && tempArrSevice.length) {
				let newStateFromState = [...stateFromState];
				tempArrSevice.forEach((srvalues) => {
					let indexVal = newStateFromState.indexOf(srvalues);
					newStateFromState.splice(indexVal, 1);
				});
				sessionStorage.setItem("lastBooking", JSON.stringify(newStateFromState));
			}
		}
	}, [stateFromState, lastBookingService, teamMembers]);

	useEffect(() => {
		if (typedDetails && typedDetails.hasOwnProperty("userName") && typedDetails.userName && typedDetails.userName.length) {
			setUserDetails({
				userName: typedDetails.userName,
				mobileNo: typedDetails.mobileNo,
				mobileNoMask: typedDetails.mobileNoMask,
			});
			setIstouched(false);
			setUserDetailsError({ ...userDetailsError, userNameError: false });
			setUserDetailsError({
				userNameError: false,
				userMobileNoError: false,
			});
		} else {
			setUserDetails({
				userName: "",
				mobileNo: "",
				mobileNoMask: "",
			});
		}
	}, [typedDetails]);

	useEffect(() => {
		if (!selectedLocation) {
			navigate("/");
			sessionStorage.clear();
		}
		if (selectedLocation?.id) {
			dispatch(serviceRetrieveAction(selectedLocation.id));
		}
	}, [dispatch, navigate, selectedLocation]);

	const blurEve = () => {};

	const serviceAddOnData = (e, serviceAmount, ser) => {
		var amount = Number(serviceAmount) / 100;
		if (e.target.checked) {
			ser.checkedFlag = true;
			setSelectedServices((serv) => [...serv, ser]);
			setTotalAmount((totalAmount) => {
				totalAmount = totalAmount + amount;
				return totalAmount;
			});
		} else {
			ser.checkedFlag = false;
			setSelectedServices(selectedServices.filter((service) => ser.id !== service.id));
			setTotalAmount((totalAmount) => {
				totalAmount = totalAmount - amount;
				return totalAmount;
			});
		}
	};

	const handleChk = (e, serviceAmount, ser) => {
		if (ser.itemData.categoryId === hairId[0].id) {
			if (selectedServices.filter((services) => services.itemData.categoryId === hairId[0].id).length > 0) {
				if (e.target.checked) {
					alert("Only One Hair Service Can Be Chosen");
				} else {
					setTotalSelectedCheckboxes(document.querySelectorAll("input[type=checkbox]:checked").length);
					serviceAddOnData(e, serviceAmount, ser);
				}
			} else {
				setTotalSelectedCheckboxes(document.querySelectorAll("input[type=checkbox]:checked").length);
				serviceAddOnData(e, serviceAmount, ser);
			}
		} else {
			setTotalSelectedCheckboxes(document.querySelectorAll("input[type=checkbox]:checked").length);
			serviceAddOnData(e, serviceAmount, ser);
		}
	};

	const userDetailsHandler = (e) => {
		e.preventDefault();
		const { name, value } = e.target ? e.target : userDetails;
		setUserDetails({
			...userDetails,
			[name]: value,
		});
		if (name === "userName") {
			if (value.trim().length < 1) {
				window.scrollTo(0, 0);
				setUserDetailsError({
					...userDetailsError,
					userNameError: true,
				});
			} else {
				if (nospecial.test(value)) {
					setIstouchedAsap(false);
					setUserDetailsError({
						...userDetailsError,
						userNameError: false,
					});
				} else {
					setUserDetailsError({
						...userDetailsError,
						userNameError: true,
					});
				}
			}
		}
		if (name === "mobileNoMask") {
			let data = value.replace(/[() -]/g, "");
			if (userDetails.mobileNoMask === value) {
				if (data.match(mobileNoPattern)) {
					setIstouchedAsap(false);
					setUserDetailsError({
						...userDetailsError,
						userMobileNoError: false,
					});
				} else {
					window.scrollTo(0, 0);
					setUserDetailsError({
						...userDetailsError,
						userMobileNoError: true,
					});
				}
			} else {
				if (data.match(mobileNoPattern)) {
					setHideBooking(false);
					setIstouchedAsap(false);
					dispatch(serviceLastBookingAction(data, selectedLocation.id));
					setUserDetailsError({
						...userDetailsError,
						userMobileNoError: false,
					});
				} else {
					setHideBooking(false);
					setUserDetailsError({
						...userDetailsError,
						userMobileNoError: true,
					});
				}
			}
		}
	};

	const submitHandler = (e) => {
		setIstouched(true);
		setView(false);
		setIstouched(true);
		setIstouchedAsap(false);
		e.preventDefault();
		sessionStorage.setItem("services", JSON.stringify(services));
		let newHair = categories.filter((values) => values.categoryData.name.toLowerCase() === "haircuts" || "hair");
		let count = 0;
		const callToRemoveHair = () => {
			services.forEach((values) => {
				if (newHair[0].id === values.itemData.categoryId) {
					if (values.checkedFlag === true) {
						count += 1;
					}
				}
			});
			return count;
		};

		let newServicesData = [];
		const removeHairCategoryValues = () => {
			let hairValueCount = callToRemoveHair();
			if (hairValueCount > 0) {
				sessionStorage.setItem("hairSelected", JSON.stringify(hairValueCount));
				services.forEach((values) => {
					if (newHair[0].id !== values.itemData.categoryId) {
						newServicesData.push(values);
					}
				});
			} else {
				sessionStorage.setItem("hairSelected", JSON.stringify(0));
				newServicesData = services;
			}
		};
		removeHairCategoryValues();
		sessionStorage.setItem("manualBookings", JSON.stringify(newServicesData));
		sessionStorage.removeItem("lastBooking");
		if (userDetailsError.userNameError === false && userDetailsError.userMobileNoError === false && selectedServices.length > 0) {
			let data = userDetails.mobileNoMask.replace(/[() -]/g, "");
			userDetails.mobileNo = data;
			dispatch(saveUserDetailsAction(userDetails, selectedServices));
			dispatch(
				teamMembersRetrieveAction({
					locationId: selectedLocation.id,
					serviceVariationId: getAllSelectedTeamMembers(selectedServices),
				})
			);
			dispatch(EmptyAsapBookingAction());
			navigate("/select-professional");
			setIstouchedAsap(false);
		}
	};

	const checkAsapWithHair = (e) => {
		setView(false);
		setIstouchedAsap(true);
		e.preventDefault();
		let tempData = {};
		let data = userDetails.mobileNoMask.replace(/[() -]/g, "");
		userDetails.mobileNo = data;
		tempData.locationId = selectedLocation.id;
		tempData.givenName = userDetails.userName;
		tempData.phoneNumber = userDetails.mobileNo;
		if (userDetailsError.userNameError === false && userDetailsError.userMobileNoError === false) {
			dispatch(saveUserDetailsAction(userDetails, []));
			dispatch(AsapBookingAction(tempData));
			setAsapTriggered(true);
		}
	};

	const checkAsapWithbaldfade = (e) => {
		setView(false);
		setIstouchedAsap(true);
		e.preventDefault();
		let tempData = {};
		let data = userDetails.mobileNoMask.replace(/[() -]/g, "");
		userDetails.mobileNo = data;
		tempData.locationId = selectedLocation.id;
		tempData.givenName = userDetails.userName;
		tempData.serviceName = "baldfade";
		tempData.phoneNumber = userDetails.mobileNo;
		if (userDetailsError.userNameError === false && userDetailsError.userMobileNoError === false) {
			dispatch(saveUserDetailsAction(userDetails, []));
			dispatch(AsapBookingAction(tempData));
			setAsapTriggered(true);
		}
	};

	useEffect(() => {
		if (asapTriggered) {
			if (asapTriggered && asapBookingStore.asapBooking !== "Sorry, No asap booking today" && asapBookingStore.loading == false) {
				navigate("/schedule");
				sessionStorage.clear();
			} else if ((asapTriggered === true && asapBookingStore.asapBooking === "Sorry, No asap booking today", asapBookingStore.loading == false)) {
				toast.error("Sorry, No Avaliable Slot Today", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				setIstouchedAsap(false);
			}
		}
	}, [asapBookingStore]);

	const closeLastBooking = () => {
		setHideBooking(false);
	};

	const showToggle = (categoryId) => {
		sessionStorage.setItem("selectedCategory", JSON.stringify(categoryId));
		setActiveCategoryId(categoryId);
	};
	const ViewSelectedServices = () => {
		setView(!view);
	};

	const getLastBookingSelectedTeamMember = (lastBookingService) => {
		if (
			lastBookingService &&
			lastBookingService.length &&
			lastBookingService[0].hasOwnProperty("appointmentSegments") &&
			lastBookingService[0].appointmentSegments &&
			lastBookingService[0].appointmentSegments.length &&
			lastBookingService[0].appointmentSegments[0].hasOwnProperty("teamMemberId") &&
			lastBookingService[0].appointmentSegments[0].teamMemberId
		) {
			return lastBookingService[0].appointmentSegments[0].teamMemberId;
		}
	};

	const rebeatBookingHandler = (e) => {
		setView(false);
		setIstouchedAsap(true);
		e.preventDefault();
		let tempLasBooking = [];
		lastBookingDetails.bookingFullData.forEach((element) => {
			tempLasBooking.push({ ...element, checkedFlag: true });
		});
		if (userDetailsError.userNameError === false && userDetailsError.userMobileNoError === false) {
			sessionStorage.removeItem("manualBookings");
			if (tempLasBooking && tempLasBooking.length) {
				let data = userDetails.mobileNoMask.replace(/[() -]/g, "");
				userDetails.mobileNo = data;
				dispatch(saveUserDetailsAction(userDetails, tempLasBooking));
				dispatch(
					teamMembersRetrieveAction({
						locationId: selectedLocation.id,
						serviceVariationId: getAllSelectedTeamMembers(lastBookingService),
					})
				);
				dispatch(EmptyAsapBookingAction());
				dispatch(saveProfessionalAction(lastBookingDetails.teamMember));
				navigate("/schedule");
				setIstouchedAsap(false);
			}
		}
	};

	useEffect(() => {
		if (typedDetails && typedDetails.mobileNo && typedDetails.mobileNo.length > 0) {
			dispatch(serviceLastBookingAction(typedDetails.mobileNo, selectedLocation.id));
		}
	}, [typedDetails]);

	return (
		<React.Fragment>
			<Header></Header>
			<section className="mt-3">
				<div className="container">
					<div className="row">
						<div className="col-auto d-flex">
							<Link to="/" style={{ color: "black" }}>
								<h4 className="mb-0 me-2">
									<i className="far fa-chevron-left align-self-center"></i>
								</h4>
							</Link>
							<h4 className="text-uppercase mb-0 ms-2">Tell us about yourself</h4>
						</div>
					</div>
				</div>
			</section>
			<section className="h-100 d-flex flex-column mt-4 h-100 overflow-auto">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<form>
								<div className="row">
									<div className="mb-3 col-lg-6">
										<label className="form-label">NAME</label>
										<input
											type="text"
											className="form-control"
											placeholder="Please Enter Your Name..."
											value={userDetails.userName}
											name="userName"
											onChange={userDetailsHandler}
										/>

										{userDetailsError.userNameError && (isTouchedAsap || isTouched) && (
											<small className="text-danger">Please Enter the Valid Name</small>
										)}
									</div>
									<div className="mb-3 col-lg-6">
										<label className="form-label">MOBILE NUMBER</label>
										<InputMask
											className="form-control"
											placeholder="Please Enter Your Mobile Number..."
											value={userDetails.mobileNoMask}
											name="mobileNoMask"
											onChange={userDetailsHandler}
											onBlur={blurEve}
											mask="+1 (999) 999-9999"
										/>
										{userDetailsError.userMobileNoError && (isTouchedAsap || isTouched) && (
											<small className="text-danger">Please Enter the Valid Number</small>
										)}
									</div>
								</div>
							</form>
							{hideBooking === true && lastBookingDetails && lastBookingDetails.bookings && lastBookingDetails.bookings.length > 0 ? (
								<div className="border">
									<div className="p-3 border-bottom">
										<div className="d-flex justify-content-between mb-3">
											<p className="mb-0">Your Last Booking</p>
											<p className="mb-0">
												<i className="bi bi-x-circle-fill text-danger" onClick={closeLastBooking} id="clickDiv"></i>
											</p>
										</div>
										{lastBookingDetails && lastBookingDetails.bookings && lastBookingDetails.bookings.length > 0 ? (
											lastBookingDetails.bookings.map((values) => {
												return (
													<>
														<h4>{values.name}</h4>
														<p>
															{values.variations[0].itemVariationData.serviceDuration &&
																Number(
																	values.variations[0].itemVariationData.serviceDuration.replace("n", "") / 1000 / 60
																)}{" "}
															Mins
														</p>
													</>
												);
											})
										) : (
											<></>
										)}
									</div>
									<div className="p-3">
										<p>Professional</p>
										<div className="d-flex">
											<div className="circle-img img36x36">
												<img src={defaultProfessional} alt="" />
											</div>
											<h5 className="mb-0 ms-2 align-self-center fw-light">
												{teamMemberName &&
												teamMemberName.hasOwnProperty("givenName") &&
												teamMemberName.givenName.toString().length > 0 ? (
													<p className="mb-0">
														{teamMemberName && teamMemberName.hasOwnProperty("givenName") && teamMemberName.givenName}
														&nbsp;
														{teamMemberName.familyName}
													</p>
												) : (
													<></>
												)}
											</h5>
										</div>
									</div>
									<div className="bg-info bg-opacity-10 text-center" id="clickDiv">
										<p
											className="mb-0 text-center btn align-self-center"
											onClick={(e) => {
												rebeatBookingHandler(e);
											}}
										>
											Repeat Last Booking <img src={Arrow} className="ms-3" alt="arrow" />
										</p>
									</div>
								</div>
							) : (
								<></>
							)}

							<h4 className={!view ? "text-uppercase mt-5" : "d-none"}>Choose Services</h4>

							<div className={!view ? "d-flex mt-4 mb-0" : "d-none"}>
								{!loading &&
									!error &&
									servicesList?.length > 0 &&
									servicesList
										.filter((service) => service.type === "CATEGORY")
										.map((val) => (
											<button
												className={"btn btn-sm btn-toolbar mx-2 " + (activeCategoryId === val.id ? "btn-primary" : "")}
												name={val.categoryData.name}
												onClick={() => showToggle(val.id, val.categoryData.name)}
												key={val.id}
											>
												{val.categoryData.name}
											</button>
										))}
							</div>
						</div>
					</div>
				</div>
				{loading && (
					<div className="container mt-5">
						<div className="d-flex justify-content-center align-items-center">
							<Loader />
						</div>
					</div>
				)}
				{!loading && (
					<div class="bg-info bg-opacity-10 mt-4 py-3 mb-3 container">
						<div class="container">
							<div class="row">
								<div class="col">
									<p class="fw-bold mb-2">I need Standard Haircut Only ASAP or BALD FADE ONLY ASAP</p>
									<div class="d-flex">
										<p class="mb-0">Express one tap Booking for Standard Haircut only or Bald Fade Apointment in Latest Available slot</p>
									</div>
								</div>
								<div className="row mt-4">
									<button
										data-bs-dismiss="modal"
										aria-label="Close"
										className={isTouchedAsap ? "btn btn-primary w-100 mb-3 disabled" : "btn btn-primary w-100 mb-3"}
										onClick={(e) => checkAsapWithHair(e)}
									>
										Proceed With Standard Haircut Only ASAP
									</button>
									<button
										data-bs-dismiss="modal"
										aria-label="Close"
										className={isTouchedAsap ? "btn btn-primary w-100 mb-3 disabled" : "btn btn-primary w-100 mb-3"}
										onClick={(e) => checkAsapWithbaldfade(e)}
									>
										Proceed With Bald Fade Only ASAP
									</button>
								</div>
							</div>

							{/* <button class="btn btn-primary w-100 d-none d-sm-block" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Confirm
            </button> */}
							<div class="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
								<div class="modal-dialog">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title" id="exampleModalLabel">
												Choose the ASAP Service ?
											</h5>
											<button
												type="button"
												class="btn-toolbar btn text-reset p-0 align-self-center"
												data-bs-dismiss="modal"
												aria-label="Close"
											>
												<i class="bi bi-x-circle-fill text-danger h3"></i>
											</button>
										</div>
										<div class="modal-body">
											<button
												data-bs-dismiss="modal"
												aria-label="Close"
												className={isTouchedAsap ? "btn btn-primary w-100 mb-3 disabled" : "btn btn-primary w-100 mb-3"}
												onClick={(e) => checkAsapWithHair(e)}
											>
												Proceed With Standard Haircut Only ASAP
											</button>
											<button
												data-bs-dismiss="modal"
												aria-label="Close"
												className={isTouchedAsap ? "btn btn-primary w-100 mb-3 disabled" : "btn btn-primary w-100 mb-3"}
												onClick={(e) => checkAsapWithbaldfade(e)}
											>
												Proceed With Bald Fade Only ASAP
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{!loading &&
					categories.length !== 0 &&
					categories.map((val) => (
						<div key={val.id} className={val.id === activeCategoryId && !view ? "" : "d-none"}>
							{services.length !== 0 &&
								services
									.filter((service) => service.itemData.categoryId === val.id)
									.map((value) => (
										<div className="d-flex border-bottom pb-3 mb-3 container" key={value.id}>
											<div className="container">
												<div className="row">
													<div className="col-auto align-self-center pe-0">
														<div className="form-check custom-checkbox">
															<input
																className="form-check-input"
																type="checkbox"
																name="hairCheck"
																id={value}
																value={value}
																checked={value.checkedFlag}
																onChange={(e) =>
																	handleChk(
																		e,
																		value.itemData.variations[0].itemVariationData.priceMoney.amount.replace("n", ""),
																		value
																	)
																}
															/>
														</div>
													</div>
													<div className="col">
														<p className="fw-light mb-0">{value.itemData.name}</p>
														<p className="small mb-0">
															{value.itemData.variations[0].itemVariationData.serviceDuration &&
																Number(
																	value.itemData.variations[0].itemVariationData.serviceDuration.replace("n", "") / 1000 / 60
																)}{" "}
															Mins
														</p>
													</div>
													<div className="col-auto align-self-center">
														<p className="fw-bold mb-0 align-self-center">
															${" "}
															{value.itemData.variations[0].itemVariationData.priceMoney.amount.replace(
																"n",

																""
															) / 100}
														</p>
													</div>
												</div>
											</div>
										</div>
									))}
						</div>
					))}
			</section>

			<section className={view ? "" : "d-none"}>
				<div className="d-flex justify-content-between p-1 border bg-aliceblue">
					<h5 class="mb-0">Selected Service</h5>
					<p className="mb-2 ml">
						<i className="bi bi-x-circle-fill text-danger" onClick={ViewSelectedServices} id="clickDiv"></i>
					</p>
				</div>
				{selectedServices.length === 0 && view && <div>No Items Selected</div>}
				{selectedServices.length > 0 &&
					selectedServices.map((value) => (
						<div key={value.id}>
							<div className="card card-body">
								<div className="d-flex border-bottom pb-3 mb-3">
									<div className="container">
										<div className="row">
											<div className="col">
												<p className="fw-light mb-0">{value.itemData.name}</p>
												<p className="small mb-0">
													{value.itemData.variations[0].itemVariationData.serviceDuration &&
														Number(
															value.itemData.variations[0].itemVariationData.serviceDuration.replace("n", "") / 1000 / 60
														)}{" "}
													Mins
												</p>
											</div>
											<div className="col-auto align-self-center">
												<p className="fw-bold mb-0 align-self-center">
													${" "}
													{value.itemData.variations[0].itemVariationData.priceMoney.amount.replace(
														"n",

														""
													) / 100}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
			</section>

			<section className="bg-white border-top py-3">
				<div className="container">
					<div className="row">
						<div className="col d-sm-flex px-3 justify-content-between">
							<div className="col-sm-6">
								<div className="d-flex justify-content-between" id="clickDiv">
									<p className="text-muted mb-0 align-self-center">
										{totalSelectedCheckboxes} Selected{" "}
										<span className="text-decoration-underline text-black" onClick={ViewSelectedServices}>
											View
										</span>
									</p>
									<h4 className="mb-0 ms-sm-3 align-self-center">Total: $ {totalAmount}</h4>
								</div>
							</div>
							{selectedServices.length === 0 && isTouched && !isTouchedAsap && (
								<small className="text-danger">Select any one of the services</small>
							)}
							<div className="col-sm-4">
								<button className="btn btn-primary w-100 mt-3 mt-sm-0 align-self-sm-center" onClick={submitHandler}>
									Proceed
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			<ToastContainer />
		</React.Fragment>
	);
};

export default ChooseService;
