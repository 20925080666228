import {
    TEAM_MEMBERS_RETRIEVE_REQUEST,
    TEAM_MEMBERS_RETRIEVE_FAIL,
    TEAM_MEMBERS_RETRIEVE_SUCCESS,
    SELECTED_PROFESSIONAL_SAVE_REQUEST,
    SELECTED_PROFESSIONAL_SAVE_SUCCESS,
    SET_TEAMMEMBERNAME_REQUEST,
    SET_TEAMMEMBERNAME_SUCCESS,
    SET_TEAMMEMBERNAME_FAIL,
} from "../constants/teamConstants";
import axios from "axios";
import { axiosHeaders } from "../dependency/axiosHeader";

export const teamMembersRetrieveAction = (teamData) => async (dispatch) => {
    try {
        dispatch({
            type: TEAM_MEMBERS_RETRIEVE_REQUEST,
        });

        const {
            data: { response },
        } = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_API_URL}/teams/searchTeamMembers`,
            headers: axiosHeaders,
            data: teamData,
        });

        dispatch({
            type: TEAM_MEMBERS_RETRIEVE_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: TEAM_MEMBERS_RETRIEVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const saveProfessionalAction =
    (selectedProfessional) => async (dispatch) => {
        try {
            dispatch({
                type: SELECTED_PROFESSIONAL_SAVE_REQUEST,
            });
            dispatch({
                type: SELECTED_PROFESSIONAL_SAVE_SUCCESS,
                payload: selectedProfessional,
            });
        } catch (error) {
            console.log(error);
        }
    };

export const TeamNameAction = (selectedProfessional) => async (dispatch) => {
    try {
        dispatch({
            type: SET_TEAMMEMBERNAME_REQUEST,
        });
        const {
            data: { response },
        } = await axios({
            method: "get",
            url: `${process.env.REACT_APP_BASE_API_URL}/teams/getTeamMemberById?id=${selectedProfessional}`,
            headers: axiosHeaders,
        });
        dispatch({
            type: SET_TEAMMEMBERNAME_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: SET_TEAMMEMBERNAME_FAIL,
        });
    }
};
