
import React from "react";
import { BrowserRouter, Route, Routes, Navigate,useLocation } from "react-router-dom";
import Home from "./components/Home";
import SelectProfessional from "./components/SelectProfessional";
import Schedule from "./components/Schedule";
import ChooseService from "./components/ChooseService";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact"
import ConfirmationBooking from "./components/ConfirmationBooking";


function App() {
  return (
    <React.Fragment>
      <React.StrictMode>
        <BrowserRouter>
          <React.Fragment>
            <Routes>
              <Route path="/confirmation" element={<ConfirmationBooking />} />
              <Route path="/" element={<Home />} />
              <Route path="/select-professional" element={<SelectProfessional />} />
              <Route path="/about-us" element={<AboutUs/>} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/service" element={<ChooseService />} />
              <Route path="/contact-us" element={<Contact/>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </React.Fragment>
        </BrowserRouter>
      </React.StrictMode>
    </React.Fragment>
  );
}

export default App;
