import {
  LOCATION_SEARCH_REQUEST,
  LOCATION_SEARCH_FAIL,
  LOCATION_SEARCH_SUCCESS,
  SELECTED_LOCATION_SAVE_REQUEST,
  SELECTED_LOCATION_SAVE_SUCCESS,
} from '../constants/locationConstants';

export const locationReducers = (state = { results: [] }, action) => {
  switch (action.type) {
    case LOCATION_SEARCH_REQUEST:
      return { ...state, loading: true };
    case LOCATION_SEARCH_SUCCESS:
      return { loading: false, results: action.payload };
    case LOCATION_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const locationSaveReducers = (state = { selectedLocation: '' }, action) => {
  switch (action.type) {
    case SELECTED_LOCATION_SAVE_REQUEST:
      return { ...state, loading: true };
    case SELECTED_LOCATION_SAVE_SUCCESS:
      return { loading: false, selectedLocation: action.payload };
    default:
      return state;
  }
};
