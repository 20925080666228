import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { useSelector, useDispatch } from "react-redux";
import locationIcon from "../../src/assets/images/MapMark.svg";
import { useNavigate } from "react-router-dom";
import { saveLocationDetails } from "../actions/locationActions";
import { useEffect } from "react";

function MapContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locationDetails.results);

  const chooseLocation = (location) => {
    dispatch(saveLocationDetails(location));
    navigate("/service");
  };

  const mapStyles = {
    width: "100%",
    height: "50%",
  };

  return locations &&
    Object.keys(locations).length &&
    locations[Object.keys(locations)[0]].hasOwnProperty("googleGeoCode") &&
    locations[Object.keys(locations)[0]].googleGeoCode &&
    locations[Object.keys(locations)[0]].googleGeoCode.length &&
    locations[Object.keys(locations)[0]].googleGeoCode[0].hasOwnProperty("geometry") &&
    locations[Object.keys(locations)[0]].googleGeoCode[0].geometry &&
    locations[Object.keys(locations)[0]].googleGeoCode[0].geometry.hasOwnProperty("location") ? (
    <Map
      google={window.google}
      zoom={10}
      style={mapStyles}
      initialCenter={{
        lat:
          locations &&
          Object.keys(locations).length &&
          locations[Object.keys(locations)[0]].hasOwnProperty("googleGeoCode") &&
          locations[Object.keys(locations)[0]].googleGeoCode &&
          locations[Object.keys(locations)[0]].googleGeoCode.length &&
          locations[Object.keys(locations)[0]].googleGeoCode[0].hasOwnProperty("geometry") &&
          locations[Object.keys(locations)[0]].googleGeoCode[0].geometry &&
          locations[Object.keys(locations)[0]].googleGeoCode[0].geometry.hasOwnProperty("location")
            ? locations[Object.keys(locations)[0]].googleGeoCode[0].geometry.location.lat
            : 35.236325538724,
        lng:
          locations &&
          Object.keys(locations).length &&
          locations[Object.keys(locations)[0]].hasOwnProperty("googleGeoCode") &&
          locations[Object.keys(locations)[0]].googleGeoCode &&
          locations[Object.keys(locations)[0]].googleGeoCode.length &&
          locations[Object.keys(locations)[0]].googleGeoCode[0].hasOwnProperty("geometry") &&
          locations[Object.keys(locations)[0]].googleGeoCode[0].geometry &&
          locations[Object.keys(locations)[0]].googleGeoCode[0].geometry.hasOwnProperty("location")
            ? locations[Object.keys(locations)[0]].googleGeoCode[0].geometry.location.lng
            : -101.6319057959214,
      }}
    >
      {Object.entries(locations).map((location, index, arr) => {
        const { googleGeoCode } = location[1];
        const { lat, lng } = googleGeoCode[0].geometry.location;
        return (
          <Marker
            key={index}
            position={{ lat: lat, lng: lng }}
            onClick={() => {
              chooseLocation(location[1]);
            }}
            icon={{
              url: locationIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        );
      })}
    </Map>
  ) : (
    <></>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(MapContainer);
