import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MapContainer from "./MapContainer";
import Loader from "./Loader/Loader";
import Header from "./Header";
import store from "../store";
import { getLocationDetails, saveLocationDetails } from "../actions/locationActions";
import { axiosHeaders } from "../dependency/axiosHeader";
import Arrow from "../assets/images/arrow.svg";
import zoomLocation from "../assets/images/location.svg";

const Home = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const locationDetails = useSelector((state) => state.locationDetails);
	const retrieveSavedLocation = useSelector((state) => state.savedLocation);
	const { loading, results } = locationDetails;
	const [pageView, setPageView] = useState("Map");

	const changeView = () => {
		if (pageView === "Map") {
			setPageView("List");
		} else {
			setPageView("Map");
		}
	};

	const chooseLocation = (location) => {
		dispatch(saveLocationDetails(location));
		navigate("/service");
	};

	useLayoutEffect(() => {
		store.dispatch({
			type: "RESET",
		});
		sessionStorage.clear();
	}, []);

	useEffect(() => {
		dispatch(getLocationDetails());
	}, [dispatch]);

	let lastDateISO = new Date();
	lastDateISO.setDate(lastDateISO.getDate() + 7);

	const stagestandardHairCut = "7V5Z2F54XT6VAMXXM4RKUTHN";
	const productionstandardHairCut = "FXOU4SFFGU7FHZCTK6OKLEOL";

	const [currentWaitingTime, setCurrentWaitingTime] = useState([]);

	const getLocationWaitingTime = async (locationId) => {
		let searchAvailabilityData = {
			startAtRange: {
				startAt: new Date().toISOString(),
				endAt: lastDateISO.toISOString(),
			},
			locationId: locationId,
			segmentFilters: [
				{
					serviceVariationId: window.location.hostname === "mensonlyhc.com" ? productionstandardHairCut : stagestandardHairCut,
				},
			],
		};
		const {
			data: { response },
		} = await axios({
			method: "post",
			url: `${process.env.REACT_APP_BASE_API_URL}/bookings/searchAvailability`,
			headers: axiosHeaders,
			data: {
				...searchAvailabilityData,
			},
		});
		if (response && response.hasOwnProperty("availabilities") && response.availabilities && response.availabilities.length) {
			return response.availabilities[0];
		}
	};

	useEffect(() => {
		if (loading) {
			setCurrentWaitingTime([]);
		} else if (!loading && results) {
			let tempWaitingTime = [];
			Object.entries(results).forEach((location) => {
				async function doSomething() {
					let tempData = await getLocationWaitingTime(location[1]?.id);
					tempWaitingTime.push(tempData);
					setCurrentWaitingTime([...tempWaitingTime]);
				}
				doSomething();
			});
		}
	}, [loading]);

	const findLocation = (currentWaitingTime, location) => {
		const tempLocation = currentWaitingTime.find((item) => item.locationId === location);
		return tempLocation;
	};

	const getHoursToWaitingTime = (time) => {
		const hours = time / 60;
		const minutes = time % 60;
		if (parseInt(hours) > 0 && minutes < 55 && minutes > 0) {
			return `${parseInt(hours)} hours ${minutes % 10 > 5 ? Math.round(minutes / 10) : parseInt(minutes / 10)}${
				minutes % 10 == 0 ? 0 : minutes % 10 > 5 ? 0 : 5
			} minutes Waiting`;
		} else if (hours > 0 && minutes > 54) {
			return `${Math.round(hours)} hours Waiting`;
		} else if (parseInt(hours) > 0) {
			return `${parseInt(hours)} hours Waiting`;
		} else if (minutes > 0) {
			return `${minutes % 10 > 5 ? Math.round(minutes / 10) : parseInt(minutes / 10)}${minutes % 10 == 0 ? 0 : minutes % 10 > 5 ? 0 : 5} minutes Waiting`;
		} else if (hours <= 0 && minutes <= 0) {
			return "No Waiting Time";
		}
	};

	return (
		<>
			<Header></Header>

			<section className="h-100 d-flex flex-column">
				<div className="d-table mx-auto mt-n5 d-md-none logo-mobile">
					<img src={require("../assets/images/logo.png")} className="img-fluid" alt="logo" />{" "}
				</div>
				<div className={pageView === "Map" ? "map-view" : "d-none"}>
					<MapContainer />
					<div className="d-flex flex-column align-self-end w-100 px-3 position-relative">
						<div className="d-flex mb-1 justify-content-between">
							<div className="input-group p-2 bg-light w-auto rounded align-self-end">
								<button className="btn btn-light" type="button" onClick={changeView}>
									<i className="bi bi-filter"></i>
								</button>
								<button className="btn btn-primary" type="button" onClick={changeView}>
									<i className="bi bi-map-fill"></i>
								</button>
							</div>

							<div className="position-relative">
								<img src={zoomLocation} className="mb-n3" srcSet="" alt="zoom" />
							</div>
						</div>

						{loading && <Loader />}
						<div className="d-flex flex-wrap flex-lg-nowrap overflow-auto bottom-tile" style={{ cursor: "pointer" }}>
							{!loading &&
								results &&
								currentWaitingTime &&
								Object.entries(results).map((location, index) => (
									<div className="service" key={location[1].id} onClick={() => chooseLocation(location[1])}>
										<div
											className="service-thumn"
											style={{
												overflow: "hidden",
												backgroundImage: `url(${location[1]?.posBackgroundUrl})`,
												width: "20vh",
												height: "20vh",
											}}
										>
											<img src={location[1]?.posBackgroundUrl} alt="" style={{ height: "100%", width: "100%" }} />
										</div>
										<div className="service-detail">
											<div className="d-flex mt-2 justify-content-between">
												<h5 className="mb-1 fw-light">{location[1].name}</h5>
												<img src={Arrow} className="see-more" alt="arrow" />
											</div>
											<p className="text-muted small" id="locationSelected">
												{location[1].address.addressLine1}, {location[1].address.locality}, {location[1].address.country},{" "}
												{location[1].address.postalCode}
											</p>
											<hr />
											<p className="mb-0 text-success">
												{currentWaitingTime && currentWaitingTime.length
													? `${getHoursToWaitingTime(
															Math.round(
																Math.abs(
																	new Date().getTime() -
																		new Date(findLocation(currentWaitingTime, location[1]?.id)?.startAt).getTime()
																) /
																	1000 /
																	60
															)
													  )}`
													: ""}
											</p>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
				<div className={pageView === "List" ? "map-view container" : "d-none"}>
					<div className="d-flex flex-column align-self-center w-100 px-3">
						<div className="d-flex justify-content-between mt-5 pt-5">
							<div className="input-group p-2 bg-light w-auto rounded align-self-end">
								<button className={pageView === "Map" ? "btn btn-light" : "btn btn-primary"} type="button" onClick={changeView}>
									<i className="bi bi-filter"></i>
								</button>
								<button className={pageView === "List" ? "btn btn-light" : "btn btn-primary"} type="button" onClick={changeView}>
									<i className="bi bi-map-fill"></i>
								</button>
							</div>
						</div>
						{loading && <Loader />}
						<div className="d-flex flex-column overflow-auto">
							{!loading &&
								results &&
								Object.entries(results).map((location, index) => (
									<div className="service" key={location[1].id} onClick={() => chooseLocation(location[1])}>
										<div
											className="service-thumn"
											style={{
												overflow: "hidden",
												backgroundImage: `url(${location[1]?.posBackgroundUrl})`,
												width: "20vh",
												height: "20vh",
											}}
										>
											{/* <img src={location[1]?.posBackgroundUrl} alt="" style={{height:"100%",width:"100%",}} /> */}
										</div>
										<div className="service-detail">
											<div className="d-flex mt-2 justify-content-between">
												<h5 className="mb-1 fw-light">{location[1].name}</h5>
												<img src={Arrow} className="see-more" alt="arrow" />
											</div>
											<p className="text-muted small" id="locationSelected">
												{location[1].address.addressLine1}, {location[1].address.locality}, {location[1].address.country},{" "}
												{location[1].address.postalCode}
											</p>
											<hr />
											<p className="mb-0 text-success">
												{currentWaitingTime && currentWaitingTime.length
													? `${getHoursToWaitingTime(
															Math.round(
																Math.abs(
																	new Date().getTime() -
																		new Date(findLocation(currentWaitingTime, location[1]?.id)?.startAt).getTime()
																) /
																	1000 /
																	60
															)
													  )}`
													: ""}
											</p>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home;
