export const TEAM_MEMBERS_RETRIEVE_REQUEST = "TEAM_MEMBERS_RETRIEVE_REQUEST";
export const TEAM_MEMBERS_RETRIEVE_SUCCESS = "TEAM_MEMBERS_RETRIEVE_SUCCESS";
export const TEAM_MEMBERS_RETRIEVE_FAIL = "TEAM_MEMBERS_RETRIEVE_FAIL";
export const SELECTED_PROFESSIONAL_SAVE_REQUEST =
    "SELECTED_PROFESSIONAL_SAVE_REQUEST";
export const SELECTED_PROFESSIONAL_SAVE_SUCCESS =
    "SELECTED_PROFESSIONAL_SAVE_SUCCESS";
export const SELECTED_PROFESSIONAL_SAVE_FAIL =
    "SELECTED_PROFESSIONAL_SAVE_FAIL";
export const SET_TEAMMEMBERNAME_REQUEST = "SET_TEAMMEMBERNAME_REQUEST";
export const SET_TEAMMEMBERNAME_SUCCESS = "SET_TEAMMEMBERNAME_SUCCESS";
export const SET_TEAMMEMBERNAME_FAIL = "SET_TEAMMEMBERNAME_FAIL";
