import {
  SERVICE_RETRIEVE_REQUEST,
  SERVICE_RETRIEVE_FAIL,
  SERVICE_RETRIEVE_SUCCESS,
  SERVICE_LAST_BOOKING_REQUEST,
  SERVICE_LAST_BOOKING_SUCCESS,
  SERVICE_LAST_BOOKING_FAIL,
} from "../constants/serviceConstants";
import axios from "axios";
import { axiosHeaders } from "../dependency/axiosHeader";

export const serviceRetrieveAction = (selectedLocationId) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_RETRIEVE_REQUEST,
    });

    const {
      data: { response },
    } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_API_URL}/catalogs/searchCatalogItems`,
      headers: axiosHeaders,
      data: {
        locationId: selectedLocationId,
      },
    });

    dispatch({
      type: SERVICE_RETRIEVE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_RETRIEVE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const serviceLastBookingAction = (phoneNumber, locationId) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_LAST_BOOKING_REQUEST,
    });

    const {
      data: { response },
    } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_API_URL}/bookings/getLastBooking`,
      headers: axiosHeaders,
      data: {
        phoneNumber: phoneNumber,
        locationId: locationId,
      },
    });

    dispatch({
      type: SERVICE_LAST_BOOKING_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_LAST_BOOKING_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const emptyLastBookingAction = () => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_LAST_BOOKING_REQUEST,
    });

    dispatch({
      type: SERVICE_LAST_BOOKING_SUCCESS,
      payload: [],
    });
  } catch (error) {
    dispatch({
      type: SERVICE_LAST_BOOKING_FAIL,
      payload:[],
    });
  }
};
