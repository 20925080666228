import { createStore, combineReducers, applyMiddleware } from "redux";
import reduxReset from "redux-reset";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
    locationReducers,
    locationSaveReducers,
} from "./reducers/locationReducers";
import { userReducers } from "./reducers/userReducers";
import {
    teamReducers,
    selectedProfessionalReducers,
    TeamNameAction,
} from "./reducers/teamReducers";
import {
    serviceReducers,
    serviceLastBookingReducers,
} from "./reducers/serviceReducers";
import {
    bookingReducers,
    bookingCancelReducers,
    asapBookingReducers,
    searchBookingReducers,
    searchDayBookingReducers,
} from "./reducers/bookingReducers";

const reducer = combineReducers({
    locationDetails: locationReducers,
    savedLocation: locationSaveReducers,
    userDetails: userReducers,
    teamMembersDetails: teamReducers,
    serviceDetails: serviceReducers,
    selectedProfessionalDetails: selectedProfessionalReducers,
    teamname: TeamNameAction,
    bookingDetails: bookingReducers,
    bookingCancel: bookingCancelReducers,
    lastBooking: serviceLastBookingReducers,
    asapBooking: asapBookingReducers,
    searchBooking: searchBookingReducers,
    searchDayBooking: searchDayBookingReducers,
});

const initialState = {};
const middleWare = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleWare), reduxReset())
);

export default store;
