import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import store from "../store";
const HeaderDesktopView = () => {
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate("/");
    };

    const clicikedLogo = () => {
        store.dispatch({
            type: "RESET",
        });
        sessionStorage.clear();
        navigate("/");
    };
    return (
        <>
            <header className="home-desk">
                <div className="col-3 position-fixed d-flex justify-content-end pt-4">
                    <img
                        src={require("../assets/images/logo.png")}
                        width="75"
                        className="d-md-block d-none"
                        alt="logo"
                        onClick={(e) => {
                            navigateToHome();
                            clicikedLogo();
                            sessionStorage.clear();
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col d-md-block d-none align-self-center py-5">
                            <nav className="d-flex justify-content-center">
                                <Link to="/">Home</Link>
                                <Link to="/about-us">About Us</Link>
                                <Link to="/contact-us">Contact</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default HeaderDesktopView;
