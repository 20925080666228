//create booking
export const BOOKING_RETRIEVE_REQUEST = 'BOOKING_RETRIEVE_REQUEST'
export const BOOKING_RETRIEVE_SUCCESS = 'BOOKING_RETRIEVE_SUCCESS'
export const BOOKING_RETRIEVE_FAIL = 'BOOKING_RETRIEVE_FAIL'

//cancel booking
export const CANCEL_BOOKING_RETRIEVE_REQUEST = 'CANCEL_BOOKING_RETRIEVE_REQUEST'
export const CANCEL_BOOKING_RETRIEVE_SUCCESS = 'CANCEL_BOOKING_RETRIEVE_SUCCESS'
export const CANCEL_BOOKING_RETRIEVE_FAIL = 'CANCEL_BOOKING_RETRIEVE_FAIL'

export const ASAPBOOKING_RETRIEVE_REQUEST = 'ASAPBOOKING_RETRIEVE_REQUEST'
export const ASAPBOOKING_RETRIEVE_SUCCESS = 'ASAPBOOKING_RETRIEVE_SUCCESS'
export const ASAPBOOKING_RETRIEVE_FAIL = 'ASAPBOOKING_RETRIEVE_FAIL'


//Search Avaliablity
export const SEARCH_BOOKING_RETRIVE_REQUEST = 'SEARCH_BOOKING_RETRIVE_REQUEST'
export const SEARCH_BOOKING_RETRIEVE_SUCCESS = 'SEARCH_BOOKING_RETRIEVE_SUCCESS'
export const SEARCH_BOOKING_RETRIEVE_FAIL = 'SEARCH_BOOKING_RETRIEVE_FAIL'

//Search Avaliablity
export const DAY_SEARCH_BOOKING_RETRIVE_REQUEST = 'DAY_SEARCH_BOOKING_RETRIVE_REQUEST'
export const DAY_SEARCH_BOOKING_RETRIEVE_SUCCESS = 'DAY_SEARCH_BOOKING_RETRIEVE_SUCCESS'
export const DAY_SEARCH_BOOKING_RETRIEVE_FAIL = 'DAY_SEARCH_BOOKING_RETRIEVE_FAIL'


//reset redux store
export const RESET_REDUX_STORE = 'RESET_REDUX_STORE'

