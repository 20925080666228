import {
    BOOKING_RETRIEVE_REQUEST,
    BOOKING_RETRIEVE_FAIL,
    BOOKING_RETRIEVE_SUCCESS,
    CANCEL_BOOKING_RETRIEVE_REQUEST,
    CANCEL_BOOKING_RETRIEVE_FAIL,
    CANCEL_BOOKING_RETRIEVE_SUCCESS,
    ASAPBOOKING_RETRIEVE_REQUEST,
    ASAPBOOKING_RETRIEVE_SUCCESS,
    ASAPBOOKING_RETRIEVE_FAIL,
    SEARCH_BOOKING_RETRIVE_REQUEST,
    SEARCH_BOOKING_RETRIEVE_SUCCESS,
    SEARCH_BOOKING_RETRIEVE_FAIL,
    DAY_SEARCH_BOOKING_RETRIVE_REQUEST,
    DAY_SEARCH_BOOKING_RETRIEVE_SUCCESS,
    DAY_SEARCH_BOOKING_RETRIEVE_FAIL,
} from "../constants/bookingConstants";
import axios from "axios";
import moment from "moment";
import { axiosHeaders } from "../dependency/axiosHeader";

export const bookingRetrieveAction = (bookingData) => async (dispatch) => {
    let dataWeGot = bookingData;
    try {
        dispatch({
            type: BOOKING_RETRIEVE_REQUEST,
        });

        const {
            data: { response },
        } = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_API_URL}/bookings/createBooking`,
            headers: axiosHeaders,
            data: {
                ...bookingData,
            },
        });

        dispatch({
            type: BOOKING_RETRIEVE_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: BOOKING_RETRIEVE_FAIL,
            payload: {
                ...dataWeGot,
            },
        });
    }
};

export const emptyBookingRetrieveAction = () => async (dispatch) => {
    try {
        dispatch({
            type: BOOKING_RETRIEVE_REQUEST,
        });
        dispatch({
            type: BOOKING_RETRIEVE_SUCCESS,
            payload: {},
        });
    } catch (error) {
        dispatch({
            type: BOOKING_RETRIEVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const bookingCancelRetrieveAction =
    (bookingCancelData) => async (dispatch) => {
        try {
            dispatch({
                type: CANCEL_BOOKING_RETRIEVE_REQUEST,
            });

            const {
                data: { response },
            } = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_API_URL}/bookings/cancelBooking`,
                headers: axiosHeaders,
                data: {
                    ...bookingCancelData,
                },
            });

            dispatch({
                type: CANCEL_BOOKING_RETRIEVE_SUCCESS,
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: CANCEL_BOOKING_RETRIEVE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const AsapBookingAction = (bookingData) => async (dispatch) => {
    try {
        dispatch({
            type: ASAPBOOKING_RETRIEVE_REQUEST,
        });

        const {
            data: { response },
        } = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_API_URL}/bookings/asapHairBooking`,
            headers: axiosHeaders,
            data: {
                ...bookingData,
            },
        });

        dispatch({
            type: ASAPBOOKING_RETRIEVE_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: ASAPBOOKING_RETRIEVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

//empty Asap

export const EmptyAsapBookingAction = () => async (dispatch) => {
    try {
        dispatch({
            type: ASAPBOOKING_RETRIEVE_REQUEST,
        });

        dispatch({
            type: ASAPBOOKING_RETRIEVE_SUCCESS,
            payload: {},
        });
    } catch (error) {
        dispatch({
            type: ASAPBOOKING_RETRIEVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

const filterUnavaliableSlots = (dateData, availabilities) => {
    let date = new Date(dateData);
    const month = date.toLocaleString("default", { month: "2-digit" });
    const year = date.getFullYear();

    let datesAvaliable = [];
    let datesBlock = [];

    const datesToFilterBlockedUnlocked = (datesTOSort, availabilities) => {
        let blockresultDates = datesTOSort.filter(function (o1) {
            return !availabilities.some(function (o2) {
                return o1 === o2.startAt.substring(0, 10);
            });
        });
        datesBlock = blockresultDates;
        let unBlockresultDates = datesTOSort.filter(function (o1) {
            return availabilities.some(function (o2) {
                return o1 === o2.startAt.substring(0, 10);
            });
        });
        datesAvaliable = unBlockresultDates;
    };

    let getDaysArray = function (year, month) {
        let monthIndex = month - 1;
        let dates = new Date(year, monthIndex, 1);
        let result = [];
        while (dates.getMonth() == monthIndex) {
            result.push(
                moment(
                    year +
                        "-" +
                        month +
                        "-" +
                        dates.getDate().toString().padStart(2, "0")
                ).format("YYYY-MM-DD")
            );
            dates.setDate(dates.getDate() + 1);
        }
        datesToFilterBlockedUnlocked(result, availabilities);
        return result;
    };

    let datesTOSort = getDaysArray(year, month);
    function dateExists(dates) {
        return availabilities.some(function (el) {
            return moment(el.startAt).format("YYYY-MM-DD") === dates;
        });
    }

    let datesFalseDate = [];
    datesTOSort.forEach((dates) => {
        let tempResult = dateExists(dates);
        if (tempResult === false) datesFalseDate.push(dates);
    });
    return [
        {
            blockDates: datesBlock,
            avaliableDates: datesAvaliable,
            availabilities: availabilities,
        },
    ];
};

const getAllDayAsBlock = (dateData) => {
    let date = new Date(dateData);
    const month = date.toLocaleString("default", { month: "2-digit" });
    const year = date.getFullYear();
    let getDaysArray = function (year, month) {
        let monthIndex = month - 1;
        let dates = new Date(year, monthIndex, 1);
        let result = [];
        while (dates.getMonth() == monthIndex) {
            result.push(
                moment(
                    year +
                        "-" +
                        month +
                        "-" +
                        dates.getDate().toString().padStart(2, "0")
                ).format("YYYY-MM-DD")
            );
            dates.setDate(dates.getDate() + 1);
        }
        return result;
    };
    let datesTOSort = getDaysArray(year, month);
    return [
        {
            blockDates: datesTOSort,
            avaliableDates: [],
            availabilities: [],
        },
    ];
};

export const searchBookingRetrieveAction =
    (searchBooking) => async (dispatch) => {
        try {
            dispatch({
                type: SEARCH_BOOKING_RETRIVE_REQUEST,
            });

            const {
                data: { response },
            } = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_API_URL}/bookings/searchAvailability`,
                headers: axiosHeaders,
                data: {
                    ...searchBooking,
                },
            });
            if (
                response &&
                response.hasOwnProperty("availabilities") &&
                response.availabilities &&
                response.availabilities.length
            ) {
                dispatch({
                    type: SEARCH_BOOKING_RETRIEVE_SUCCESS,
                    payload: filterUnavaliableSlots(
                        response.availabilities[0].startAt,
                        response.availabilities
                    ),
                });
            } else {
                dispatch({
                    type: SEARCH_BOOKING_RETRIEVE_SUCCESS,
                    payload: getAllDayAsBlock(
                        searchBooking.startAtRange.startAt
                    ),
                });
                dispatch(searchDayBookingRetrieveAction([]));
            }
        } catch (error) {
            dispatch({
                type: SEARCH_BOOKING_RETRIEVE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const searchDayBookingRetrieveAction =
    (searchBooking) => async (dispatch) => {
        try {
            dispatch({
                type: DAY_SEARCH_BOOKING_RETRIVE_REQUEST,
            });

            if (
                searchBooking &&
                searchBooking.hasOwnProperty("startAtRange") &&
                searchBooking.hasOwnProperty("segmentFilters")
            ) {
                const {
                    data: { response },
                } = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_BASE_API_URL}/bookings/searchAvailability`,
                    headers: axiosHeaders,
                    data: {
                        ...searchBooking,
                    },
                });

                dispatch({
                    type: DAY_SEARCH_BOOKING_RETRIEVE_SUCCESS,
                    payload: response.availabilities,
                });
            } else {
                dispatch({
                    type: DAY_SEARCH_BOOKING_RETRIEVE_SUCCESS,
                    payload: [],
                });
            }
        } catch (error) {
            dispatch({
                type: DAY_SEARCH_BOOKING_RETRIEVE_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const searchBookingRetrieveActionEmpty = () => async (dispatch) => {
    try {
        dispatch({
            type: SEARCH_BOOKING_RETRIVE_REQUEST,
        });
        dispatch({
            type: SEARCH_BOOKING_RETRIEVE_SUCCESS,
            payload: [],
        });
    } catch (error) {
        dispatch({
            type: SEARCH_BOOKING_RETRIEVE_FAIL,
            payload: [],
        });
    }
};
