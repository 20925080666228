import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { bookingCancelRetrieveAction } from "../actions/bookingActions";
import store from "../store";
import "add2calendar/css/add2calendar.css";
import Add2CalendarComponent from "./AddToCalender";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultProfessional from "../assets/images/defaultProfile.png";
import { emptyBookingRetrieveAction } from "../actions/bookingActions";
import { saveUserDetailsAction } from "../actions/userActions";
import { serviceLastBookingAction } from "../actions/serviceActions";
import { teamMembersRetrieveAction, TeamNameAction } from "../actions/teamActions";
import HeaderDesktopView from "./HeaderDestopView";
import moment from "moment";
import Home from "./Home";

const ConfirmationBooking = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state.userDetails);
	const locationList = useSelector((state) => state.savedLocation);
	const { selectedLocation } = locationList;
	const professionalDetails = useSelector((state) => state.selectedProfessionalDetails.selectedProfessional);
	const teamMembersList = useSelector((state) => state.teamMembersDetails);
	const bookingDetailsStatus = useSelector((state) => state.bookingDetails);
	const bookingCancelStatus = useSelector((state) => state.bookingCancel.BookingsList);
	let typedServiceDetails = useSelector((state) => state.userDetails.user?.services);
	const teamMemberName = useSelector((state) => state.teamname.professionalName.teamMember);
	const [touched, setTouched] = useState(false);
	const [cancelled, setCancelled] = useState(false);

	//services
	const servicesDetails = useSelector((state) => state.serviceDetails);
	const { servicesList, loading, error } = servicesDetails;
	let servicesData = servicesList ? servicesList?.filter((service) => service.type === "ITEM") : "";
	const [bookedService, setBookedService] = useState([]);
	const [userSelectedProfessional, setUserSelectedProfessional] = useState([]);

	useEffect(() => {
		if (!selectedLocation) {
			store.dispatch({
				type: "RESET",
			});
			navigate("/");
			sessionStorage.clear();
		}
	}, []);

	const callCancel = () => {
		let bookingCancel = {
			bookingId: bookingDetailsStatus.BookingsList.id,
		};
		sessionStorage.clear();
		dispatch(bookingCancelRetrieveAction(bookingCancel));
		setTouched(true);
	};

	const redirectHomePage = () => {
		store.dispatch({
			type: "RESET",
		});
		sessionStorage.clear();
		navigate("/");
	};

	useEffect(() => {
		if (
			touched &&
			bookingCancelStatus &&
			bookingCancelStatus.booking &&
			bookingCancelStatus.booking.hasOwnProperty("status") &&
			bookingCancelStatus.booking.status === "CANCELLED_BY_SELLER" &&
			bookingCancelStatus.errors &&
			bookingCancelStatus.errors.length <= 0
		) {
			setTouched(true);
			setCancelled(true);
			store.dispatch({
				type: "RESET",
			});

			toast.success("Appointment Cancelled Successfully.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			window.history.replaceState(null, "Men's Only", "/");
		} else if (touched && bookingCancelStatus && bookingCancelStatus.errors && bookingCancelStatus.errors.length > 0) {
			setCancelled(true);
			store.dispatch({
				type: "RESET",
			});
			toast.error("Appointment Cancelled failed.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			window.history.replaceState(null, "Men's Only", "/");
		}
	}, [bookingCancelStatus]);

	const [serviceDurationData, setServiceDurationData] = useState({
		duration: 0,
		serviceData: "",
	});

	const addAGuest = () => {
		navigate("/service");
	};

	useEffect(() => {
		if (
			bookingDetailsStatus &&
			bookingDetailsStatus.hasOwnProperty("BookingsList") &&
			bookingDetailsStatus.BookingsList &&
			bookingDetailsStatus.BookingsList.appointmentSegments &&
			bookingDetailsStatus.BookingsList.appointmentSegments.length &&
			servicesList &&
			servicesList.length
		) {
			let bookingService = [];
			bookingDetailsStatus.BookingsList.appointmentSegments.forEach((values) => {
				const filteredResults = servicesData.filter((item) => item.itemData.variations[0].id === values.serviceVariationId);
				bookingService.push(filteredResults[0]);
			});
			setBookedService(bookingService);
			let duration = 0;
			let serverOrdered = "";
			if (bookingService && bookedService.length) {
				bookingService.forEach((element) => {
					serverOrdered += `${element.itemData.name} `;
					duration +=
						element.itemData.variations[0].itemVariationData.serviceDuration &&
						Number(element.itemData.variations[0].itemVariationData.serviceDuration.replace("n", "") / 1000 / 60);
				});
				setServiceDurationData({
					duration: duration,
					serviceData: serverOrdered,
				});
			}
			if (
				bookingService &&
				bookingService.length &&
				teamMembersList &&
				teamMembersList.hasOwnProperty("teamMembers") &&
				teamMembersList.teamMembers &&
				teamMembersList.teamMembers.length &&
				teamMemberName &&
				teamMemberName.hasOwnProperty("givenName")
			) {
				const filteredteamResults = teamMembersList.teamMembers.filter((item) => item.teamMember === teamMemberName.id);
				if (filteredteamResults && filteredteamResults.length) {
					let profileImage = filteredteamResults[0].profileImageUrl;
					setUserSelectedProfessional({
						...teamMemberName,
						profileImage,
					});
				} else {
					setUserSelectedProfessional(teamMemberName);
				}
			} else if (teamMemberName && teamMemberName.hasOwnProperty("givenName")) {
				setUserSelectedProfessional(teamMemberName);
			}
		}
	}, [bookingDetailsStatus, servicesDetails, teamMembersList, teamMemberName]);

	const getAllSelectedTeamMembersLastBooking = (services) => {
		let objServiceId = {};
		services.forEach((values) => {
			let serviceId = values.serviceVariationId;
			objServiceId[serviceId] = [values.teamMemberId];
		});
		return objServiceId;
	};

	useEffect(() => {
		if (
			bookingDetailsStatus &&
			bookingDetailsStatus.hasOwnProperty("BookingsList") &&
			bookingDetailsStatus.BookingsList &&
			bookingDetailsStatus.BookingsList.appointmentSegments &&
			bookingDetailsStatus.BookingsList.appointmentSegments.length
		) {
			dispatch(TeamNameAction(bookingDetailsStatus.BookingsList.appointmentSegments[0].teamMemberId));
		}
	}, [bookingDetailsStatus]);

	return (
		<>
			<ToastContainer />
			{!cancelled ? (
				<>
					<HeaderDesktopView></HeaderDesktopView>
					<div className="d-flex flex-column h-100">
						<section className="bg-primary d-sm-none">
							.{" "}
							<div className="container py-4">
								<div className="row">
									<div className="col text-end">
										<i
											className="bi bi-x-circle-fill text-white h3"
											style={{ cursor: "pointer" }}
											onClick={(e) => {
												redirectHomePage();
											}}
										></i>
									</div>
									<div className="row">
										<div className="col text-center">
											<img src={require("../assets/images/logo-white.png")} alt="" />
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="h-100 d-flex flex-column mt-4 h-100 overflow-auto">
							<div className="container bg-sm-white py-3">
								<div className="row">
									<div className="col">
										<div className="row">
											<div className="col">
												{bookingDetailsStatus &&
												bookingDetailsStatus.hasOwnProperty("BookingsList") &&
												bookingDetailsStatus.BookingsList.hasOwnProperty("status") &&
												bookingDetailsStatus.BookingsList.status === "ACCEPTED" ? (
													<h4 className="mb-0 fw-light text-success">Appointment Confirmed</h4>
												) : (
													<h4 className="mb-0 fw-light text-danger">Appointment Failed</h4>
												)}
												<hr />
												<div className="d-flex mt-4">
													<div>
														<img
															src={selectedLocation.posBackgroundUrl}
															alt=""
															style={{
																width: "15vh",
																height: "15vh",
															}}
														/>
													</div>
													<hr />
													<div className="align-self-center ms-3">
														{selectedLocation && (
															<>
																{" "}
																<p className="mb-0">{selectedLocation.name}</p>
																<p className="mb-0 text-muted small">
																	{selectedLocation && selectedLocation.googleGeoCode[0].formatted_address}{" "}
																</p>
															</>
														)}
													</div>
												</div>
												<hr />
												<div className="row align-items-start">
													{serviceDurationData.serviceData.toString().length > 0 &&
													bookingDetailsStatus &&
													bookingDetailsStatus.hasOwnProperty("BookingsList") &&
													bookingDetailsStatus.BookingsList.hasOwnProperty("status") &&
													bookingDetailsStatus.BookingsList.status === "ACCEPTED" ? (
														<>
															<div className="col-auto mb-0 text-info">
																Selected Time : {moment(bookingDetailsStatus.BookingsList.startAt).format("ddd MMM D, LT")}.
															</div>
															<div className="col-auto">
																<Add2CalendarComponent
																	props={bookingDetailsStatus}
																	date={bookingDetailsStatus.BookingsList.startAt}
																	location={selectedLocation}
																	services={serviceDurationData}
																/>
															</div>
														</>
													) : (
														<></>
													)}
												</div>
												<hr />
												<p className="text-uppercase">Your Services</p>
												{bookedService &&
													bookedService.map((service) => (
														<div>
															<p className="fw-light mb-0">{service.itemData.name}</p>
															<p className="small">
																{service.itemData.variations[0].itemVariationData.serviceDuration &&
																	Number(
																		service.itemData.variations[0].itemVariationData.serviceDuration.replace("n", "") /
																			1000 /
																			60
																	)}{" "}
																Mins
															</p>
														</div>
													))}
												<hr />
												<p className="text-uppercase">Professional</p>
												<div className="d-flex">
													<div className="circle-img img36x36 me-3">
														{userSelectedProfessional && userSelectedProfessional.hasOwnProperty("profileImageUrl") ? (
															<img src={userSelectedProfessional.profileImageUrl} alt="" />
														) : (
															<img src={defaultProfessional} alt="" />
														)}
														{userSelectedProfessional &&
															userSelectedProfessional.hasOwnProperty("profileImageUrl") &&
															userSelectedProfessional.profileImageUrl.length <= 0 && <img src={defaultProfessional} alt="" />}
													</div>
													<div className="align-self-center">
														{userSelectedProfessional &&
														userSelectedProfessional.hasOwnProperty("givenName") &&
														userSelectedProfessional.givenName.toString().length > 0 ? (
															<p className="mb-0">
																{userSelectedProfessional &&
																	userSelectedProfessional.hasOwnProperty("givenName") &&
																	userSelectedProfessional.givenName}
																&nbsp;
																{userSelectedProfessional.familyName}
															</p>
														) : (
															<></>
														)}
														{userSelectedProfessional &&
														userSelectedProfessional.hasOwnProperty("displayName") &&
														userSelectedProfessional.displayName.toString().length > 0 ? (
															<p className="mb-0">
																{userSelectedProfessional &&
																	userSelectedProfessional.hasOwnProperty("displayName") &&
																	userSelectedProfessional.displayName}
															</p>
														) : (
															<></>
														)}
													</div>
												</div>
												<div className="d-flex border-top border-bottom text-center justify-content-around mt-3">
													<div className="py-3 flex-fill">
														<a href={`tel:${selectedLocation && selectedLocation.phoneNumber}`}>
															<p className="text mb-0 btn textFormat text-center">
																<i className="h1 bi bi-telephone-outbound-fill"></i>
																<br />
																<p className="text-muted mb-0">Call</p>
															</p>
														</a>
													</div>
													<div className="border-start border-end py-3 flex-fill">
														<a
															href={`https://www.google.com/maps/dir//${
																selectedLocation && selectedLocation.googleGeoCode[0].formatted_address
															}/@${selectedLocation && selectedLocation.googleGeoCode[0].geometry.location.lat},${
																selectedLocation && selectedLocation.googleGeoCode[0].geometry.location.lng
															},15z`}
															target="_blank"
														>
															<p className="text mb-0 btn textFormat">
																{" "}
																<i className="h1 bi bi-geo-alt-fill"></i>
																<br />
																<p className="text-muted mb-0">Directions</p>
															</p>
														</a>
													</div>
													<div className="py-3 flex-fill">
														{bookingDetailsStatus &&
														bookingDetailsStatus.hasOwnProperty("BookingsList") &&
														bookingDetailsStatus.BookingsList.hasOwnProperty("status") &&
														bookingDetailsStatus.BookingsList.status === "ACCEPTED" ? (
															<>
																{" "}
																<p
																	className="mb-0 btn textFormat"
																	style={{
																		color: "black !important",
																	}}
																	data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																>
																	<i className="h1 bi bi-x-circle-fill text-danger"></i>
																	<br />
																	<p className="text-muted mb-0">Cancel</p>
																</p>
															</>
														) : (
															<>
																{" "}
																<i className="h1 bi bi-x-circle-fill text-danger"></i>
																<br />
																<p
																	className="mb-0 btn disabled"
																	style={{
																		color: "black !important",
																	}}
																>
																	Cancel
																</p>
															</>
														)}
													</div>
												</div>
												<div>
													<div className="container">
														<div
															className="modal fade"
															id="exampleModal"
															tabIndex="-1"
															aria-labelledby="exampleModalLabel"
															aria-hidden="true"
														>
															<div className="modal-dialog">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5 className="modal-title" id="exampleModalLabel">
																			Are you sure you want to cancel ?
																		</h5>
																		<button
																			type="button"
																			className="btn-toolbar btn text-reset p-0 align-self-center"
																			data-bs-dismiss="modal"
																			aria-label="Close"
																		>
																			<i className="bi bi-x-circle-fill text-danger h3"></i>
																		</button>
																	</div>
																	<div className="modal-body">
																		<button
																			data-bs-dismiss="modal"
																			aria-label="Close"
																			className={"btn btn-primary w-100 mb-3"}
																			onClick={(e) => callCancel()}
																		>
																			YES
																		</button>
																		<button
																			data-bs-dismiss="modal"
																			aria-label="Close"
																			className={"btn btn-secondary w-100 mb-3"}
																		>
																			NO
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<button className="mt-3 btn btn-outline-dark w-100 mt-4 mb-3" onClick={(e) => addAGuest()}>
													Add a Guest
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</>
			) : (
				<Home />
			)}
		</>
	);
};

export default ConfirmationBooking;
