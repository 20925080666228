import {
  BOOKING_RETRIEVE_REQUEST,
  BOOKING_RETRIEVE_FAIL,
  BOOKING_RETRIEVE_SUCCESS, CANCEL_BOOKING_RETRIEVE_REQUEST,
  CANCEL_BOOKING_RETRIEVE_FAIL,
  CANCEL_BOOKING_RETRIEVE_SUCCESS,ASAPBOOKING_RETRIEVE_REQUEST,
  ASAPBOOKING_RETRIEVE_SUCCESS,
  ASAPBOOKING_RETRIEVE_FAIL, SEARCH_BOOKING_RETRIVE_REQUEST,
  SEARCH_BOOKING_RETRIEVE_SUCCESS,
  SEARCH_BOOKING_RETRIEVE_FAIL,
  DAY_SEARCH_BOOKING_RETRIVE_REQUEST,
  DAY_SEARCH_BOOKING_RETRIEVE_SUCCESS,
  DAY_SEARCH_BOOKING_RETRIEVE_FAIL

} from "../constants/bookingConstants";

export const bookingReducers = (state = { BookingsList: [] }, action) => {
  switch (action.type) {
    case BOOKING_RETRIEVE_REQUEST:
      return { ...state, loading: true };
    case BOOKING_RETRIEVE_SUCCESS:
      return { loading: false, BookingsList: action.payload };
    case BOOKING_RETRIEVE_FAIL:
      return { loading: false, BookingsList: action.payload,error:"error" };
    default:
      return state;
  }
};

export const bookingCancelReducers = (state = { BookingsCancelList: [] }, action) => {
  switch (action.type) {
    case CANCEL_BOOKING_RETRIEVE_REQUEST:
      return { ...state, loading: true };
    case CANCEL_BOOKING_RETRIEVE_SUCCESS:
      return { loading: false, BookingsList: action.payload };
    case CANCEL_BOOKING_RETRIEVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const asapBookingReducers = (state = { asapBooking: [] }, action) => {
  switch (action.type) {
    case ASAPBOOKING_RETRIEVE_REQUEST:
      return { ...state, loading: true };
    case ASAPBOOKING_RETRIEVE_SUCCESS:
      return { loading: false, asapBooking: action.payload };
    case ASAPBOOKING_RETRIEVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchBookingReducers = (state = { SearchBookingsList: [] }, action) => {
  switch (action.type) {
    case SEARCH_BOOKING_RETRIVE_REQUEST:
      return { ...state, loading: true };
    case SEARCH_BOOKING_RETRIEVE_SUCCESS:
      return { loading: false, SearchBookingsList: action.payload };
    case SEARCH_BOOKING_RETRIEVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchDayBookingReducers = (state = { SearchDayBookingsList: [] }, action) => {
  switch (action.type) {
    case DAY_SEARCH_BOOKING_RETRIVE_REQUEST:
      return { ...state, loading: true };
    case DAY_SEARCH_BOOKING_RETRIEVE_SUCCESS:
      return { loading: false, SearchBookingsList: action.payload };
    case DAY_SEARCH_BOOKING_RETRIEVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

