import React, { useEffect, useState } from "react";
import store from "../store";
import Header from "./Header";
import { useDispatch } from "react-redux";

const Contact = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		store.dispatch({
			type: "RESET",
		});
		sessionStorage.clear();
	}, []);
	return (
		<div className="d-flex flex-column h-100">
			<Header></Header>
			<section className="h-100 d-flex flex-column mt-4 h-100 overflow-auto">
				<div className="container py-3">
					<div className="row">
						<div className="col-4">
							<div className="rounded w-100">
								<img src={require("../assets/images/Rectangle1.png")} alt="" className="w-100" />
							</div>
						</div>
						<div className="col-4">
							<div className="rounded w-100">
								<img src={require("../assets/images/Rectangle2.png")} alt="" className="w-100" />
							</div>
						</div>
						<div className="col-4">
							<div className="rounded w-100">
								<img src={require("../assets/images/Rectangle3.png")} alt="" className="w-100" />
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-sm-12 text-left">
							<h4>Contact Us</h4>
							<h5>Western</h5>
							<p className="h6">
								<span>Phone:</span>
								<a className="p contactlink" href={`tel:8064182528`}>
									{" "}
									806-418-2528
								</a>
							</p>
							<p className="h6">
								<span>Address:&nbsp;</span>
								<span className="p contactp">6010 S. Western Suite 200, Amarillo, TX 79110</span>
							</p>
						</div>
					</div>
					<div className="row mt-2">
						<div className="col-sm-12 text-left">
							<h5>Gem Lake Rd</h5>
							<p className="h6">
								<span>Phone:</span>
								<a className="p contactlink" href={`tel:8064186038`}>
									{" "}
									806-418-6038
								</a>
							</p>
							<p className="h6">
								<span>Address:&nbsp;</span>
								<span className="p contactp">5512 Gem Lake Road, Suite 300 Amarillo, TX 79106</span>
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Contact;
