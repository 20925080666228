import * as React from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "./Loader/Loader";
import { userSelectedTimeSlot, saveUserDetailsAction } from "../actions/userActions";
import { bookingRetrieveAction, searchBookingRetrieveAction, searchDayBookingRetrieveAction } from "../actions/bookingActions";
import Header from "./Header";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Schedule = () => {
	const navigate = useNavigate();
	const [date, setDate] = React.useState(new Date());
	const dispatch = useDispatch();
	const [loader, setLoader] = React.useState(true);
	const [touched, setTouched] = useState(false);
	const [bookingDone, setBookingDone] = useState(false);
	const selectedTimeSlotData = useSelector((state) => state.userDetails.timeSlot);
	const [selectedTimeSlot, setSelectedTimeSlot] = useState(" ");
	const locationList = useSelector((state) => state.savedLocation);
	const { selectedLocation } = locationList;
	const selectedTeamMember = useSelector((state) => state.selectedProfessionalDetails.selectedProfessional);
	const selectedLocationID = useSelector((state) => state.savedLocation.selectedLocation);
	const selectedServiceData = useSelector((state) => state.userDetails.user?.services);
	const bookingDetailsStatus = useSelector((state) => state.bookingDetails);
	const dayBasedBooking = useSelector((state) => state.searchDayBooking);
	let searchBookingSlotAvailability = useSelector((state) => state.searchBooking);
	const [dayBasedBookingArr, setdayBasedBookingArr] = useState(dayBasedBooking);
	const asapBooking = useSelector((state) => state.asapBooking);
	const [timeAvailablityDateBased, setTimeAvailablityDateBased] = useState(null);
	const [serviceCategories, setServiceCategories] = useState([]);
	const [slotDuration, setSlotDuration] = useState(0);
	//Last Booking

	//create booking
	const [createUserBooking, setCreateUserBooking] = useState({});
	const [previousMonth, setPreviousMonth] = useState(new Date().toLocaleString("default", { month: "2-digit" }));
	const userInfo = useSelector((state) => state.userDetails.user?.userInfo);
	const [startDate, setStartDate] = useState(new Date());
	const day = startDate.toLocaleString("default", { day: "2-digit" });
	const year = startDate.getFullYear();
	const monthName = startDate.toLocaleString("default", { month: "long" });
	const [calenderRender, setCalenderRender] = useState(false);
	//services
	const servicesDetails = useSelector((state) => state.serviceDetails);
	const { servicesList, loading, error } = servicesDetails;
	let servicesData = servicesList ? servicesList?.filter((service) => service.type === "ITEM") : "";
	const [services, setServices] = useState(servicesData);
	const categories = servicesList ? servicesList?.filter((service) => service.type === "CATEGORY") : "";
	const [activeCategoryId, setActiveCategoryId] = useState();
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedServices, setSelectedServices] = useState([]);
	const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(0);
	const [view, setView] = useState(false);
	const [isTouched, setIstouched] = useState(false);
	const [addOnServices, setAddOnServices] = useState([]);
	const [unSelectServices, setunSelectedServices] = useState([]);
	const [holidays, setholidays] = useState([]);
	const [addOnServicesCount, setAddOnServicesCount] = useState(0);
	const [addOnServicesCountTouched, setAddOnServicesCountTouched] = useState(false);
	const [addOnServicesData, setAddOnServicesData] = useState([]);
	let getOffset = new Date().getTimezoneOffset();
	//get Hair Id
	const [hairId, setHairId] = useState("");
	//get professional id based on slot
	const [getProffessionalBySlot, setGetProffessionalBySlot] = useState("");

	useEffect(() => {
		setunSelectedServices([]);
		let manualBookings = JSON.parse(sessionStorage.getItem("manualBookings"));
		let lastBooking = JSON.parse(sessionStorage.getItem("lastBooking"));
		if (manualBookings && manualBookings.length > 0) {
			let tempmanualBookingsdData = manualBookings.filter((values) => values.checkedFlag !== true);
			setunSelectedServices(tempmanualBookingsdData);
		} else if (lastBooking && lastBooking.length > 0) {
			let templastBookingData = lastBooking.filter((values) => values.checkedFlag !== true);
			setunSelectedServices(templastBookingData);
		}
	}, []);

	useEffect(() => {
		setdayBasedBookingArr(dayBasedBooking);
		if (
			dayBasedBooking &&
			dayBasedBooking.loading == false &&
			dayBasedBooking.hasOwnProperty("SearchBookingsList") &&
			dayBasedBooking.SearchBookingsList.length
		) {
			dayBasedBooking.SearchBookingsList.forEach((values) => {
				let firstDate = new Date(values.startAt);
			});
			setCreateUserBooking({
				...dayBasedBooking.SearchBookingsList[0],
				...altUserInfo,
			});
			dispatch(userSelectedTimeSlot(dayBasedBooking.SearchBookingsList[0]));
			setSelectedTimeSlot(dayBasedBooking.SearchBookingsList[0]);
		}
	}, [dayBasedBooking]);

	useEffect(() => {
		if (!selectedLocation) {
			navigate("/");
			sessionStorage.clear();
		}
	}, [navigate, selectedLocation]);

	let altUserInfo = {
		givenName: userInfo?.userName,
		phoneNumber: userInfo?.mobileNo,
	};

	const tempSearchAvailabilityData = () => {
		let tempServiceAvaliableArr = [];
		if (selectedServiceData && selectedServiceData.length > 0) {
			selectedServiceData.forEach((data) => {
				let tempData = {};
				tempData.serviceVariationId = data.itemData.variations[0].id;
				if (selectedTeamMember?.hasOwnProperty("firstAvailableTeamMeamber")) {
					tempData.teamMemberIdFilter = {
						any: selectedTeamMember.firstAvailableTeamMeamber.teamMemberId,
					};
				} else {
					tempData.teamMemberIdFilter = {
						any: [selectedTeamMember.teamMember],
					};
				}
				tempServiceAvaliableArr.push(tempData);
			});
		} else if (
			asapBooking &&
			asapBooking.hasOwnProperty("asapBooking") &&
			asapBooking.asapBooking &&
			asapBooking.asapBooking.hasOwnProperty("appointmentSegments") &&
			asapBooking.asapBooking.appointmentSegments.length
		) {
			let tempData = {};
			tempData.serviceVariationId = asapBooking.asapBooking.appointmentSegments[0].serviceVariationId;
			tempData.teamMemberIdFilter = {
				any: [asapBooking.asapBooking.appointmentSegments[0].teamMemberId],
			};
			tempServiceAvaliableArr.push(tempData);
			let tempNonSelectedData = services.filter((values) => values.itemData.variations[0].id !== tempData.serviceVariationId);
			setunSelectedServices(tempNonSelectedData);
		}
		return tempServiceAvaliableArr;
	};

	function getFirstDayOfNextMonth(date) {
		return new Date(date.getFullYear(), date.getMonth(), 1);
	}

	const callToCheckSlotAvailability = (date) => {
		const addZero = (val) => {
			if (val < 10) {
				return "0" + val;
			}
			return val;
		};
		const startDate = new Date(date);
		const month = date.toLocaleString("default", { month: "2-digit" });
		let startDateISO = startDate.toISOString();
		let currentMonth = startDate.getMonth();
		let lastDay = new Date(startDate.getFullYear(), currentMonth + 1, 1);
		const hours = Math.abs(lastDay - startDate) / 36e5;
		let lastMonth = moment(lastDay).format("MM");
		let lastYear = lastDay.getFullYear();
		if (lastMonth == 0) {
			lastMonth = 12;
			lastYear = lastYear - 1;
		}
		let lastDateISO = `${lastYear}-${addZero(parseInt(lastMonth))}-${moment(lastDay).format("DD")}T00:00:00.000Z`;

		if (hours <= 24) {
			lastDay.setDate(lastDay.getDate() + 30);
			let lastMonth = lastDay.getMonth();
			if (lastMonth - startDate.getMonth() >= 2) {
				lastMonth = lastMonth - 1;
			}
			let lastDate = new Date(lastDay.getFullYear(), lastMonth + 1, 1);
			lastDateISO = `${lastDay.getFullYear()}-${addZero(parseInt(lastMonth))}-${addZero(lastDate.getUTCDate())}T00:00:00.000Z`;
		}

		if (month === moment(new Date()).format("MM")) {
			startDateISO = new Date().toISOString();
		} else if (month === moment(date).format("MM")) {
			startDateISO = getFirstDayOfNextMonth(date);
		}

		if (selectedServiceData && selectedServiceData.length > 0) {
			let searchAvailabilityData = {
				startAtRange: {
					startAt: startDateISO,
					endAt: lastDateISO,
				},
				locationId: selectedLocationID.id,
				segmentFilters: tempSearchAvailabilityData(),
			};
			dispatch(searchBookingRetrieveAction(searchAvailabilityData));
		} else if (asapBooking && asapBooking.hasOwnProperty("asapBooking") && asapBooking.asapBooking) {
			let searchAvailabilityData = {
				startAtRange: {
					startAt: startDateISO,
					endAt: lastDateISO,
				},
				locationId: selectedLocationID.id,
				segmentFilters: tempSearchAvailabilityData(),
			};
			dispatch(searchBookingRetrieveAction(searchAvailabilityData));
		}
	};

	useEffect(() => {
		if (selectedServiceData && selectedServiceData.length) {
			callToCheckSlotAvailability(new Date());
		}
	}, [selectedServiceData]);

	useEffect(() => {
		if (
			asapBooking &&
			asapBooking.hasOwnProperty("asapBooking") &&
			asapBooking.asapBooking &&
			asapBooking.asapBooking.hasOwnProperty("appointmentSegments") &&
			asapBooking.asapBooking.appointmentSegments.length
		) {
			callToCheckSlotAvailability(new Date());
		}
	}, [asapBooking]);

	useEffect(() => {
		if (selectedTimeSlotData && selectedTimeSlotData.hasOwnProperty("startAt") && selectedTimeSlotData.startAt.toString().length > 0) {
			setSelectedTimeSlot(selectedTimeSlotData);
			timeSlot(selectedTimeSlotData);
		} else if (
			searchBookingSlotAvailability &&
			searchBookingSlotAvailability.hasOwnProperty("SearchBookingsList") &&
			searchBookingSlotAvailability.SearchBookingsList &&
			searchBookingSlotAvailability.SearchBookingsList.length &&
			searchBookingSlotAvailability.SearchBookingsList[0] &&
			searchBookingSlotAvailability.SearchBookingsList[0].availabilities &&
			searchBookingSlotAvailability.SearchBookingsList[0].availabilities.length
		) {
			setSelectedTimeSlot(
				searchBookingSlotAvailability.SearchBookingsList.length &&
					searchBookingSlotAvailability.SearchBookingsList[0].availabilities &&
					searchBookingSlotAvailability.SearchBookingsList[0].availabilities[0]
			);
			setTimeAvailablityDateBased(
				searchBookingSlotAvailability.SearchBookingsList[0].availabilities.filter(
					(values) =>
						moment(values.startAt).format("YYYY-MM-DD") ===
						moment(searchBookingSlotAvailability.SearchBookingsList[0].availabilities[0].startAt).format("YYYY-MM-DD")
				)
			);
			setLoader(false);
			timeSlot(
				searchBookingSlotAvailability.SearchBookingsList.length &&
					searchBookingSlotAvailability.SearchBookingsList[0].availabilities &&
					searchBookingSlotAvailability.SearchBookingsList[0].availabilities[0]
			);
		}
	}, [searchBookingSlotAvailability, selectedTimeSlotData]);

	const setAvailableTime = (newDate) => {
		setDate(newDate);
		setTimeAvailablityDateBased([]);
		if (previousMonth != newDate.toLocaleString("default", { month: "2-digit" })) {
			setPreviousMonth(newDate.toLocaleString("default", { month: "2-digit" }));
			setDate(newDate);
			setCalenderRender(false);
			setLoader(true);
			dispatch(userSelectedTimeSlot({}));
		}
		setTimeAvailablityDateBased(
			searchBookingSlotAvailability.SearchBookingsList[0].availabilities.filter(
				(values) => moment(values.startAt).format("YYYY-MM-DD") === moment(newDate).format("YYYY-MM-DD")
			)
		);
	};

	const [slotError, setSlotError] = useState(false);
	const checkRemainingTime = (value, round = 1, time) => {
		const getExactTiming = moment(new Date(time)).format("hh:mm A");
		const filteredValue =
			dayBasedBookingArr &&
			dayBasedBookingArr.loading == false &&
			dayBasedBookingArr.hasOwnProperty("SearchBookingsList") &&
			dayBasedBookingArr.SearchBookingsList.length &&
			dayBasedBookingArr.SearchBookingsList.filter((newDate) => moment(new Date(newDate?.startAt)).format("hh:mm A") === getExactTiming);
		if (filteredValue && filteredValue.length === 0) {
			setSlotError(true);
		}
		let remainingTime = value - slotDuration;
		let tempArr = round;
		if (remainingTime >= 1) {
			tempArr += 1;
			const travelTime = moment(new Date(time)).add(slotDuration, "minutes").format();
			checkRemainingTime(remainingTime, tempArr, travelTime);
		}
		return round;
	};

	const checkAddonsTimeAvailabilityFun = (time) => {
		setSlotError(false);
	};

	const timeSlot = (time) => {
		checkAddonsTimeAvailabilityFun(time?.startAt);
		time.appointmentSegments.forEach((element) => {
			if (typeof element.serviceVariationVersion !== "number") {
				const [digit, word] = element.serviceVariationVersion.match(/\D+|\d+/g);
				element.serviceVariationVersion = Number(digit);
			}
		});
		setGetProffessionalBySlot(time.appointmentSegments[0].teamMemberId);
		setCreateUserBooking({ ...time, ...altUserInfo });
		dispatch(userSelectedTimeSlot(time));
	};

	const createBooking = (createUserBooking) => {
		setTouched(true);
		setBookingDone(true);
		dispatch(bookingRetrieveAction(createUserBooking));
	};

	const createAsapRepeatBooking = () => {
		setTouched(true);
		setBookingDone(true);
		dispatch(bookingRetrieveAction(createUserBooking));
	};

	useEffect(() => {
		if (
			bookingDetailsStatus &&
			bookingDetailsStatus.hasOwnProperty("BookingsList") &&
			bookingDetailsStatus.BookingsList.hasOwnProperty("status") &&
			bookingDetailsStatus.BookingsList.status === "ACCEPTED" &&
			touched === true
		) {
			navigate("/confirmation");
		} else if (bookingDetailsStatus && bookingDetailsStatus.hasOwnProperty("error") && touched === true) {
			navigate("/confirmation");
		}
	}, [bookingDetailsStatus]);

	useEffect(() => {
		if (timeAvailablityDateBased && timeAvailablityDateBased.length) {
			setSelectedTimeSlot(timeAvailablityDateBased[0]);
			timeSlot(timeAvailablityDateBased[0]);
		}
	}, [timeAvailablityDateBased]);

	const searchForEachDate = (date) => {
		const month = date.toLocaleString("default", { month: "2-digit" });
		const day = date.toLocaleString("default", { day: "2-digit" });
		const year = date.getFullYear();
		const startDate = `${year}-${month}-${day}T00:00:00.000Z`;
		const endDate = `${year}-${month}-${day}T24:00:00.000Z`;
		if (selectedServiceData && selectedServiceData.length > 0) {
			let searchAvailabilityData = {
				startAtRange: {
					startAt: startDate,
					endAt: endDate,
				},
				locationId: selectedLocationID.id,
				segmentFilters: tempSearchAvailabilityData(),
			};
			dispatch(searchDayBookingRetrieveAction(searchAvailabilityData));
		} else if (asapBooking && asapBooking.hasOwnProperty("asapBooking") && asapBooking.asapBooking) {
			let searchAvailabilityData = {
				startAtRange: {
					startAt: startDate,
					endAt: endDate,
				},
				locationId: selectedLocationID.id,
				segmentFilters: tempSearchAvailabilityData(),
			};
			dispatch(searchDayBookingRetrieveAction(searchAvailabilityData));
		}
	};

	//services
	useEffect(() => {
		let fetchSelectedServiceData = JSON.parse(sessionStorage.getItem("services"));
		// For Reference Remove the particular hair category
		// let newHair = categories.filter((values) => values.categoryData.name.toLowerCase() === "haircuts" || "hair");
		// if (newHair && newHair.length) {
		// 	setHairId(newHair[0].id);
		// }
		// if (JSON.parse(sessionStorage.getItem("hairSelected")) >= 1) {
		// 	let HairIndex = categories.indexOf(newHair[0]);
		// 	categories.splice(HairIndex, 1);
		// 	setServiceCategories(categories);
		// } else {
		// 	setServiceCategories(categories);
		// }
		const prod_addons = "KMNUMMRX4PIPHFARV5RQUMM2";
		const dev_addons = "JLKDMONTUFJ3J7OBZ7HS5SAP";
		const checkServerHost = window.location.hostname === "mensonlyhc.com";
		const addon_value = checkServerHost ? prod_addons : dev_addons;
		setServiceCategories(categories.filter((values) => values.id === addon_value));
		if (servicesList) {
			setActiveCategoryId(categories.length !== 0 ? addon_value : "");
			servicesList.forEach((element) => {
				element.checkedFlag = false;
			});
			servicesData = servicesList ? servicesList?.filter((service) => service.type === "ITEM") : "";
			setServices(servicesData);
		}
		if (fetchSelectedServiceData && fetchSelectedServiceData.length > 0) {
			let newServices = [];
			let tempArr = [];
			setTotalAmount([]);
			setSelectedServices([]);
			setTotalSelectedCheckboxes(0);
			fetchSelectedServiceData.forEach((element) => {
				newServices.push(element);
				if (element.itemData.variations[0].itemVariationData.hasOwnProperty("priceMoney") && element.checkedFlag === true) {
					tempArr.push(element);
					let amount = Number(element.itemData.variations[0].itemVariationData.priceMoney.amount.replace("n", "") / 100);
					setTotalSelectedCheckboxes((totalSelectedCheckboxes) => totalSelectedCheckboxes + Number(1));
					setTotalAmount((totalAmount) => {
						totalAmount = Number(totalAmount) + Number(amount);
						return totalAmount;
					});
				}
			});
			setSelectedServices(tempArr);
			setServices(newServices);
			setIstouched(true);
		}
	}, [servicesList]);

	const showToggle = (categoryId) => {
		setActiveCategoryId(categoryId);
	};

	const serviceAddOnData = (e, serviceAmount, ser) => {
		var amount = Number(serviceAmount) / 100;
		if (e.target.checked) {
			ser.checkedFlag = true;
			setAddOnServicesCountTouched(false);
			setAddOnServicesCount((countNum) => countNum + 1);
			setSelectedServices((serv) => [...serv, ser]);
			setAddOnServicesData((addOnServicesData) => [...addOnServicesData, ser]);
			setTotalAmount((totalAmount) => {
				totalAmount = totalAmount + amount;
				return totalAmount;
			});
		} else {
			ser.checkedFlag = false;
			setAddOnServicesCount((countNum) => countNum - 1);
			setSelectedServices(selectedServices.filter((service) => ser.id !== service.id));
			setAddOnServicesData(addOnServicesData.filter((service) => ser.id !== service.id));
			setTotalAmount((totalAmount) => {
				totalAmount = totalAmount - amount;
				return totalAmount;
			});
		}
	};

	const handleChk = (e, serviceAmount, ser) => {
		if (ser.itemData.categoryId === hairId) {
			if (selectedServices.filter((services) => services.itemData.categoryId === hairId).length > 0) {
				if (e.target.checked) {
					alert("Only One Hair Service Can Be Choosed");
				} else {
					setTotalSelectedCheckboxes(document.querySelectorAll("input[type=checkbox]:checked").length);
					serviceAddOnData(e, serviceAmount, ser);
				}
			} else {
				setTotalSelectedCheckboxes(document.querySelectorAll("input[type=checkbox]:checked").length);
				serviceAddOnData(e, serviceAmount, ser);
			}
		} else {
			setTotalSelectedCheckboxes(document.querySelectorAll("input[type=checkbox]:checked").length);
			serviceAddOnData(e, serviceAmount, ser);
		}
	};

	useEffect(() => {
		if (selectedServices && selectedServices.length > 0) {
			let tempArr = [];
			selectedServices.forEach((element) => {
				if (element.itemData.variations[0].itemVariationData.hasOwnProperty("priceMoney") && element.checkedFlag === true) {
					let serviceTime =
						element.itemData.variations[0].itemVariationData.serviceDuration &&
						Number(element.itemData.variations[0].itemVariationData.serviceDuration.replace("n", "") / 1000 / 60);
					//  let amount = Number(element.itemData.variations[0].itemVariationData.priceMoney.amount.replace("n", "") / 100);
					let version = Number(element.version.replace("n", "") / 1);
					let variationsid = element.itemData.variations[0].id;
					let teamMemberIdData = "";
					if (selectedTeamMember.hasOwnProperty("firstAvailableTeamMeamber")) {
						teamMemberIdData = getProffessionalBySlot;
					} else {
						teamMemberIdData = selectedTeamMember.teamMemberId;
					}
					tempArr.push({
						durationMinutes: Number(serviceTime),
						serviceVariationId: variationsid,
						teamMemberId: teamMemberIdData,
						serviceVariationVersion: Number(version),
					});
				}
			});
			setAddOnServices(tempArr);
		}
	}, [selectedServices, createUserBooking]);

	const proceedWithAddons = () => {
		setAddOnServicesCountTouched(true);
		if (addOnServicesCount && addOnServicesCount > 0 && !slotError) {
			let manualBookings = JSON.parse(sessionStorage.getItem("manualBookings"));
			let newCreatingBooking = { ...createUserBooking };
			let { appointmentSegments } = newCreatingBooking;
			newCreatingBooking.appointmentSegments = [];
			if (manualBookings && manualBookings.length > 0) {
				newCreatingBooking.appointmentSegments = [...addOnServices];
			} else {
				newCreatingBooking.appointmentSegments = [...appointmentSegments, ...addOnServices];
			}
			dispatch(saveUserDetailsAction(altUserInfo, selectedServices));
			createBooking(newCreatingBooking);
		}
	};

	const proceedWithOutAddons = () => {
		sessionStorage.setItem("manualBookings", JSON.stringify(services));
		createBooking(createUserBooking);
	};

	const onMonthChangeDetection = (e) => {
		callToCheckSlotAvailability(e);
		setDate(e);
		setTimeAvailablityDateBased([]);
		dispatch(userSelectedTimeSlot(null));
	};

	useEffect(() => {
		if (
			searchBookingSlotAvailability &&
			searchBookingSlotAvailability.hasOwnProperty("SearchBookingsList") &&
			searchBookingSlotAvailability.SearchBookingsList &&
			searchBookingSlotAvailability.SearchBookingsList.length &&
			searchBookingSlotAvailability.SearchBookingsList[0].blockDates &&
			searchBookingSlotAvailability.SearchBookingsList[0].blockDates.length &&
			searchBookingSlotAvailability.hasOwnProperty("loading") &&
			searchBookingSlotAvailability.loading === false &&
			searchBookingSlotAvailability.SearchBookingsList[0].avaliableDates &&
			searchBookingSlotAvailability.SearchBookingsList[0].avaliableDates.length
		) {
			let firstDate = new Date(searchBookingSlotAvailability.SearchBookingsList[0].avaliableDates[0]);
			setStartDate(new Date(firstDate.getTime() + getOffset * 60000));
			searchForEachDate(new Date(firstDate.getTime() + getOffset * 60000));
			let newDates = [];
			searchBookingSlotAvailability.SearchBookingsList[0].blockDates.forEach((values) => {
				const myArray = values.split("-");
				newDates.push(new Date(myArray[0] * 1, myArray[1] * 1 - 1, myArray[2] * 1));
			});
			setholidays(newDates);
		} else if (
			searchBookingSlotAvailability &&
			searchBookingSlotAvailability.hasOwnProperty("SearchBookingsList") &&
			searchBookingSlotAvailability.SearchBookingsList &&
			searchBookingSlotAvailability.SearchBookingsList.length &&
			searchBookingSlotAvailability.SearchBookingsList[0].blockDates &&
			searchBookingSlotAvailability.SearchBookingsList[0].blockDates.length
		) {
			let newDates = [];
			setStartDate(new Date(searchBookingSlotAvailability.SearchBookingsList[0].blockDates[0]));
			searchBookingSlotAvailability.SearchBookingsList[0].blockDates.forEach((values) => {
				const myArray = values.split("-");
				newDates.push(new Date(myArray[0] * 1, myArray[1] * 1 - 1, myArray[2] * 1));
			});
			setholidays(newDates);
		}
	}, [searchBookingSlotAvailability]);

	useEffect(() => {
		setSlotError(false);
		if (addOnServicesData && addOnServicesData.length) {
			let totalAddonTime = 0;
			addOnServicesData.forEach((value) => {
				totalAddonTime +=
					value.itemData.variations[0].itemVariationData.serviceDuration &&
					Number(value.itemData.variations[0].itemVariationData.serviceDuration.replace("n", "") / 1000 / 60);
			});
			createUserBooking?.appointmentSegments?.forEach((values) => {
				totalAddonTime += values?.durationMinutes;
			});
			checkRemainingTime(totalAddonTime, 1, selectedTimeSlotData?.startAt);
		}
	}, [addOnServicesData]);

	useEffect(() => {
		setSlotError(false);
		if (addOnServicesData && addOnServicesData.length) {
			let totalAddonTime = 0;
			addOnServicesData.forEach((value) => {
				totalAddonTime +=
					value.itemData.variations[0].itemVariationData.serviceDuration &&
					Number(value.itemData.variations[0].itemVariationData.serviceDuration.replace("n", "") / 1000 / 60);
			});
			createUserBooking?.appointmentSegments?.forEach((values) => {
				totalAddonTime += values?.durationMinutes;
			});
			checkRemainingTime(totalAddonTime, 1, selectedTimeSlotData?.startAt);
		}
	}, [selectedTimeSlotData]);

	React.useMemo(() => {
		if (
			dayBasedBookingArr &&
			dayBasedBookingArr.loading == false &&
			dayBasedBookingArr.hasOwnProperty("SearchBookingsList") &&
			dayBasedBookingArr.SearchBookingsList.length &&
			dayBasedBookingArr.SearchBookingsList
		) {
			let tempTimeValue = 0;
			if (dayBasedBookingArr?.SearchBookingsList[0]?.appointmentSegments && dayBasedBookingArr?.SearchBookingsList[0]?.appointmentSegments?.length) {
				dayBasedBookingArr?.SearchBookingsList[0]?.appointmentSegments?.forEach((values) => {
					tempTimeValue += values?.durationMinutes;
				});
			}
			setSlotDuration(tempTimeValue);
		}
	}, [dayBasedBookingArr]);

	return (
		<React.Fragment>
			<Header></Header>
			<section className="mt-3">
				<div className="container">
					<div className="row">
						<div className="col-auto d-flex">
							{JSON.parse(sessionStorage.getItem("manualBookings")) && JSON.parse(sessionStorage.getItem("manualBookings")).length ? (
								<Link to="/select-professional" style={{ color: "black" }}>
									<h4 className="mb-0 me-2">
										<i className="far fa-chevron-left align-self-center"></i>
									</h4>
								</Link>
							) : (
								<Link to="/service" style={{ color: "black" }}>
									<h4 className="mb-0 me-2">
										<i className="far fa-chevron-left align-self-center"></i>
									</h4>
								</Link>
							)}
							<h4 className="text-uppercase mb-0 ms-2">Select a Time</h4>
						</div>
					</div>
				</div>
			</section>
			<section className="h-100 d-flex flex-column mt-4 h-100 overflow-auto">
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="row">
								<div className="col-sm-6">
									{searchBookingSlotAvailability?.loading == false &&
									searchBookingSlotAvailability &&
									searchBookingSlotAvailability.hasOwnProperty("SearchBookingsList") &&
									searchBookingSlotAvailability.SearchBookingsList &&
									searchBookingSlotAvailability.SearchBookingsList.length &&
									searchBookingSlotAvailability.SearchBookingsList[0].blockDates ? (
										<div className="mt-4">
											<DatePicker
												selected={startDate}
												onChange={(date) => {
													searchForEachDate(date);
													setStartDate(date);
												}}
												excludeDates={holidays}
												onMonthChange={onMonthChangeDetection}
												minDate={new Date()}
												inline
											/>
										</div>
									) : (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												margin: "auto",
												marginTop: "50%",
											}}
										>
											{searchBookingSlotAvailability?.error ? <p>Please Try Again Later</p> : <Loader />}
										</div>
									)}
								</div>

								{searchBookingSlotAvailability?.loading == false &&
									searchBookingSlotAvailability &&
									searchBookingSlotAvailability.hasOwnProperty("SearchBookingsList") &&
									searchBookingSlotAvailability.SearchBookingsList &&
									searchBookingSlotAvailability.SearchBookingsList.length &&
									searchBookingSlotAvailability.SearchBookingsList[0].blockDates && (
										<div className="col-sm-6" style={{ paddingLeft: "30px" }}>
											<p className="mb-0 mt-4 small text-muted">
												Available Times on {day} {monthName} {year}
											</p>
											<div className="d-flex flex-wrap mt-3">
												{dayBasedBookingArr &&
												dayBasedBookingArr.loading == false &&
												dayBasedBookingArr.hasOwnProperty("SearchBookingsList") &&
												dayBasedBookingArr.SearchBookingsList.length ? (
													dayBasedBookingArr.SearchBookingsList.map((time, index) => (
														<>
															<button
																className={`btn btn-sm rounded mb-3 me-2 border ${
																	selectedTimeSlot.startAt === time.startAt ? "bg-info bg-opacity-10 border-info" : "bg-white"
																}`}
																style={{
																	width: "100px",
																}}
																onClick={() => {
																	timeSlot(time);
																}}
															>
																{new Date(time.startAt).toLocaleTimeString("en-US", {
																	hour: "numeric",
																	minute: "numeric",
																})}
															</button>
														</>
													))
												) : (
													<>
														{dayBasedBookingArr &&
														dayBasedBookingArr.loading == false &&
														dayBasedBookingArr.hasOwnProperty("SearchBookingsList") &&
														dayBasedBookingArr.SearchBookingsList.length == 0 ? (
															<p>No slots available</p>
														) : (
															<>
																<Loader />
															</>
														)}
													</>
												)}
											</div>
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bg-info bg-opacity-10 p-3">
				<p className="mb-0 small text-center">Selected Professional is not available on dates that are grayed out</p>
			</section>
			<section className="bg-white border-top py-3">
				<div className="container">
					<div className="row">
						<div className="col-sm-auto m-auto">
							{JSON.parse(sessionStorage.getItem("manualBookings")) && JSON.parse(sessionStorage.getItem("manualBookings")).length > 0 ? (
								<>
									<button
										className="btn btn-primary w-100 d-none d-sm-block"
										data-bs-toggle="modal"
										data-bs-target="#exampleModal"
										disabled={
											searchBookingSlotAvailability?.loading ||
											searchBookingSlotAvailability?.error ||
											dayBasedBookingArr?.loading ||
											dayBasedBookingArr?.error ||
											dayBasedBookingArr?.SearchBookingsList?.length == 0
										}
									>
										Confirm
									</button>
									<button
										className="btn btn-primary w-100 d-block d-sm-none"
										data-bs-toggle="offcanvas"
										data-bs-target="#offcanvasBottom"
										aria-controls="offcanvasBottom"
										disabled={
											searchBookingSlotAvailability?.loading ||
											searchBookingSlotAvailability?.error ||
											dayBasedBookingArr?.loading ||
											dayBasedBookingArr?.error ||
											dayBasedBookingArr?.SearchBookingsList?.length == 0
										}
									>
										Confirm
									</button>
									<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
										<div className="modal-dialog modal-dialog-scrollable">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title" id="exampleModalLabel">
														Add On Services
													</h5>
													<button
														type="button"
														className="btn-toolbar btn text-reset p-0 align-self-center"
														data-bs-dismiss="modal"
														aria-label="Close"
													>
														<i className="bi bi-x-circle-fill text-danger h3"></i>
													</button>
												</div>
												<div className="modal-body">
													{addOnServicesCount === 0 && addOnServicesCountTouched === true ? (
														<small className="text-danger">No Addon Services Selected</small>
													) : (
														""
													)}
													{slotError ? <small className="text-danger">Selected Addon Slots Are Not Avaliable</small> : ""}
													<div className="">
														{/* For Referrence 
														<div className={!view ? "d-flex mb-2" : "d-none"}>
															{!loading &&
																!error &&
																serviceCategories?.length > 0 &&
																serviceCategories.map((val) => (
																	<button
																		className={
																			"btn btn-sm btn-toolbar mx-2 " + (activeCategoryId === val.id ? "btn-primary" : "")
																		}
																		name={val.categoryData.name}
																		onClick={() => showToggle(val.id, val.categoryData.name)}
																		key={val.id}
																	>
																		{val.categoryData.name}
																	</button>
																))}
														</div> */}
														{!loading &&
															categories.length !== 0 &&
															categories.map((val) => (
																<div key={val.id} className={val.id === activeCategoryId && !view ? "" : "d-none"}>
																	{unSelectServices.length !== 0 &&
																		unSelectServices
																			.filter((service) => service.itemData.categoryId === val.id)
																			.map((value) => (
																				<div className="d-flex border-bottom pb-3 mb-3 container" key={value.id}>
																					<div className="container">
																						<div className="row">
																							<div className="col-auto align-self-center pe-0">
																								<div className="form-check custom-checkbox">
																									<input
																										className="form-check-input"
																										type="checkbox"
																										name="hairCheck"
																										id={value}
																										value={value}
																										checked={value.checkedFlag}
																										onChange={(e) => {
																											handleChk(
																												e,
																												value.itemData.variations[0].itemVariationData.priceMoney.amount.replace(
																													"n",
																													""
																												),
																												value
																											);
																										}}
																									/>
																								</div>
																							</div>
																							<div className="col">
																								<p className="fw-light mb-0">{value.itemData.name}</p>
																								<p className="small mb-0">
																									{value.itemData.variations[0].itemVariationData
																										.serviceDuration &&
																										Number(
																											value.itemData.variations[0].itemVariationData.serviceDuration.replace(
																												"n",
																												""
																											) /
																												1000 /
																												60
																										)}{" "}
																									Mins
																								</p>
																							</div>
																							<div className="col-auto align-self-center">
																								<p className="fw-bold mb-0 align-self-center">
																									${" "}
																									{value.itemData.variations[0].itemVariationData.priceMoney.amount.replace(
																										"n",

																										""
																									) / 100}
																								</p>
																							</div>
																						</div>
																					</div>
																				</div>
																			))}
																</div>
															))}
													</div>
												</div>

												<div className="modal-footer">
													{!slotError && (
														<button
															className="btn btn-primary w-100 mb-3"
															data-bs-dismiss={addOnServicesCount === 0 ? "" : "modal"}
															aria-label={addOnServicesCount === 0 ? "" : "Close"}
															onClick={proceedWithAddons}
														>
															Proceed with Addon Services selected
														</button>
													)}
													<button
														className="btn btn-outline-secondary w-100"
														data-bs-dismiss="modal"
														aria-label="Close"
														onClick={proceedWithOutAddons}
													>
														Proceed without Add On Services
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
										<div className="offcanvas-header">
											<h5 className="offcanvas-title fw-light" id="offcanvasBottomLabel">
												Add On Services
											</h5>
											<button
												type="button"
												className="btn-toolbar btn text-reset p-0 align-self-center"
												data-bs-dismiss="offcanvas"
												aria-label="Close"
											>
												<i className="bi bi-x-circle-fill text-danger h3"></i>
											</button>
										</div>
										<div className="offcanvas-body small">
											{addOnServicesCount === 0 && addOnServicesCountTouched === true ? (
												<small className="text-danger">No Addon Services Selected</small>
											) : (
												""
											)}
											{slotError ? <small className="text-danger">Selected Addon Slots Are Not Avaliable</small> : ""}
											<div className={!view ? "d-flex mt-4 mb-0" : "d-none"}>
												{!loading &&
													!error &&
													serviceCategories?.length > 0 &&
													serviceCategories
														.filter((service) => service.type === "CATEGORY")
														.map((val) => (
															<button
																className={"btn btn-sm btn-toolbar mx-2 " + (activeCategoryId === val.id ? "btn-primary" : "")}
																name={val.categoryData.name}
																onClick={() => showToggle(val.id, val.categoryData.name)}
																key={val.id}
															>
																{val.categoryData.name}
															</button>
														))}
											</div>
											<hr></hr>
											{!loading &&
												categories.length !== 0 &&
												categories.map((val) => (
													<div key={val.id} className={val.id === activeCategoryId && !view ? "" : "d-none"}>
														{unSelectServices.length !== 0 &&
															unSelectServices
																.filter((service) => service.itemData.categoryId === val.id)
																.map((value) => (
																	<div className="d-flex border-bottom pb-3 mb-3 container" key={value.id}>
																		<div className="container">
																			<div className="row">
																				<div className="col-auto align-self-center pe-0">
																					<div className="form-check custom-checkbox">
																						<input
																							className="form-check-input"
																							type="checkbox"
																							name="hairCheck"
																							id={value}
																							value={value}
																							checked={value.checkedFlag}
																							onChange={(e) =>
																								handleChk(
																									e,
																									value.itemData.variations[0].itemVariationData.priceMoney.amount.replace(
																										"n",
																										""
																									),
																									value
																								)
																							}
																						/>
																					</div>
																				</div>
																				<div className="col">
																					<p className="fw-light mb-0">{value.itemData.name}</p>
																					<p className="small mb-0">
																						{value.itemData.variations[0].itemVariationData.serviceDuration &&
																							Number(
																								value.itemData.variations[0].itemVariationData.serviceDuration.replace(
																									"n",
																									""
																								) /
																									1000 /
																									60
																							)}{" "}
																						Mins
																					</p>
																				</div>
																				<div className="col-auto align-self-center">
																					<p className="fw-bold mb-0 align-self-center">
																						${" "}
																						{value.itemData.variations[0].itemVariationData.priceMoney.amount.replace(
																							"n",

																							""
																						) / 100}
																					</p>
																				</div>
																			</div>
																		</div>
																	</div>
																))}
													</div>
												))}
											{!slotError && (
												<button
													className="btn btn-primary w-100 mb-3"
													data-bs-dismiss={addOnServicesCount === 0 ? "" : "offcanvas"}
													aria-label={addOnServicesCount === 0 ? "" : "Close"}
													onClick={proceedWithAddons}
												>
													Proceed with Add On Services
												</button>
											)}
											<button
												className="btn btn-outline-secondary w-100"
												data-bs-dismiss="offcanvas"
												aria-label="Close"
												onClick={proceedWithOutAddons}
											>
												Proceed without Add On Services
											</button>
										</div>
									</div>
								</>
							) : (
								<>
									<button
										className={`btn btn-primary w-100 ${
											bookingDone ||
											searchBookingSlotAvailability?.loading ||
											searchBookingSlotAvailability?.error ||
											dayBasedBookingArr?.loading ||
											dayBasedBookingArr?.error ||
											dayBasedBookingArr?.SearchBookingsList?.length == 0
												? "disabled"
												: ""
										}`}
										onClick={createAsapRepeatBooking}
									>
										Confirm
									</button>
								</>
							)}
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Schedule;
