import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    getLocationDetails,
    saveLocationDetails,
} from "../actions/locationActions";
import store from "../store";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate("/");
    };

    const clicikedLogo = () => {
        store.dispatch({
            type: "RESET",
        });
        sessionStorage.clear();
        dispatch(getLocationDetails());
        navigate("/");
    };

    return (
        <>
            <header className="home-desk">
                <div className="col-3 position-fixed d-flex justify-content-end pt-4">
                    <img
                        src={require("../assets/images/logo.png")}
                        width="75"
                        className="d-md-block d-none"
                        alt="logo"
                        onClick={(e) => {
                            navigateToHome();
                            clicikedLogo();
                            sessionStorage.clear();
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </div>

                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col d-md-block d-none align-self-center py-5">
                            <nav className="d-flex justify-content-center">
                                <Link to="/">Home</Link>
                                <Link to="/about-us">About Us</Link>
                                <Link to="/contact-us">Contact</Link>
                            </nav>
                        </div>
                        <div className="col-auto d-md-none d-block">
                            <button
                                className="btn btn-toolbar p-0"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasExample"
                                aria-controls="offcanvasExample"
                            >
                                <i className="bi bi-list hamburg-icon"></i>
                            </button>
                            <div
                                className="offcanvas offcanvas-start"
                                tabIndex="-1"
                                id="offcanvasExample"
                                aria-labelledby="offcanvasExampleLabel"
                            >
                                <div className="offcanvas-header justify-content-end">
                                    <button
                                        type="button"
                                        className="btn-close text-reset"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="offcanvas-body">
                                    <img
                                        src={require("../assets/images/logo.png")}
                                        className="d-table w-50 mx-auto"
                                        alt="logo"
                                        onClick={navigateToHome}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <nav className="d-flex flex-column text-start">
                                        <Link to="/">Home</Link>
                                        <Link to="/about-us">About Us</Link>
                                        <Link to="/contact-us">Contact</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
