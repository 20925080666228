import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { teamMembersRetrieveAction, saveProfessionalAction } from "../actions/teamActions";
import defaultProfessional from "../assets/images/defaultProfile.png";
import Loader from "./Loader/Loader";
import Header from "./Header";

const SelectProfessional = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const teamMembersList = useSelector((state) => state.teamMembersDetails);
	const ProfessionalDetails = useSelector((state) => state.selectedProfessionalDetails.selectedProfessional);
	const { teamMembers, loading, error } = teamMembersList;
	const [selectedProfessional, setSelectedProfessional] = useState(ProfessionalDetails ? ProfessionalDetails : "");
	const [activeprofessionalId, setActiveProfessionalId] = useState(ProfessionalDetails ? ProfessionalDetails.id : "");
	const locationList = useSelector((state) => state.savedLocation);
	const { selectedLocation } = locationList;
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		if (!selectedLocation) {
			navigate("/");
		}
	}, []);

	useEffect(() => {
		if (!activeprofessionalId && teamMembers && teamMembers.length && ProfessionalDetails == {}) {
			if (teamMembers && teamMembers[0] && teamMembers[0].hasOwnProperty("firstAvailableTeamMeamber")) {
				setActiveProfessionalId(teamMembers[0].id);
			} else {
				setActiveProfessionalId(teamMembers[0].teamMemberId);
			}
			setSelectedProfessional(teamMembers[0]);
		}
	}, [teamMembers]);

	useEffect(() => {
		if (ProfessionalDetails && ProfessionalDetails.hasOwnProperty("teamMemberId")) {
			if (ProfessionalDetails && ProfessionalDetails.hasOwnProperty("firstAvailableTeamMeamber")) {
				setActiveProfessionalId(ProfessionalDetails.id);
			} else {
				setActiveProfessionalId(ProfessionalDetails.teamMemberId);
			}
			setSelectedProfessional(ProfessionalDetails);
		}
	}, [ProfessionalDetails]);

	useEffect(() => {
		if (teamMembersList && teamMembersList.hasOwnProperty("teamMembers") && teamMembersList.teamMembers && teamMembersList.teamMembers.length) {
			setLoader(false);
		}
	}, [teamMembersList]);

	const showToggle = (member) => {
		if (member && member.hasOwnProperty("firstAvailableTeamMeamber")) {
			setActiveProfessionalId(member.id);
		} else {
			setActiveProfessionalId(member.teamMemberId);
		}
		setSelectedProfessional(member);
	};

	const submitHandler = () => {
		dispatch(saveProfessionalAction(selectedProfessional));
		navigate("/schedule");
	};

	return (
		<React.Fragment>
			<Header></Header>
			<section className="mt-3">
				<div className="container">
					<div className="row">
						<div className="col-auto d-flex">
							<Link to="/service" style={{ color: "black" }}>
								<h4 className="mb-0 me-2">
									<i className="far fa-chevron-left align-self-center"></i>
								</h4>
							</Link>
							<h4 className="text-uppercase mb-0 ms-2">Select a Professional</h4>
						</div>
					</div>
				</div>
			</section>
			{loading && (
				<div className="container mt-5">
					<div className="d-flex justify-content-center align-items-center">
						<Loader />
					</div>
				</div>
			)}
			<section className="h-100 d-flex flex-column mt-4 h-100 overflow-auto">
				<div className="container">
					<div className="row">
						<div className="col">
							{!loading && teamMembers && teamMembers.length ? (
								teamMembers.map((member) => (
									<div
										className={
											activeprofessionalId === (member.teamMemberId ? member.teamMemberId : member.id)
												? "bg-info bg-opacity-10 border-info border p-3 rounded mb-3"
												: "bg-white p-3 rounded border mb-3"
										}
										onClick={() => showToggle(member)}
										key={member.teamMemberId}
									>
										<div className="d-flex">
											<div className="circle-img img60x60">
												{member && member.hasOwnProperty("profileImageUrl") ? (
													<img src={member.profileImageUrl} alt="" />
												) : (
													<img src={defaultProfessional} alt="" />
												)}
												{member && member.hasOwnProperty("profileImageUrl") && member.profileImageUrl.length <= 0 && (
													<img src={defaultProfessional} alt="" />
												)}
											</div>
											<div className="ms-3">
												<h5 className="fw-light">{member.displayName}</h5>
												<p className="mb-0 text-success small">
													{member.waitTimeInMinutes === 60
														? `${Number(member?.waitTime?.split(" ")?.[0]) + 1} hours waiting`
														: member.waitTime}
												</p>
												<p className="mb-0 text-success small"></p>
											</div>
										</div>
									</div>
								))
							) : (
								<>{!loading && <p>Please Try Again Later</p>}</>
							)}
						</div>
					</div>
				</div>
			</section>
			<section className="bg-white border-top py-3">
				<div className="container">
					<div className="row">
						<div className="col">
							<button className="btn btn-primary w-100" onClick={submitHandler} disabled={loading || error != null}>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default SelectProfessional;
