import {
  USER_SAVE_REQUEST,
  USER_SAVE_FAIL,
  USER_SELECTED_TIME_SLOT,
  USER_SAVE_SUCCESS,
} from "../constants/userConstants";

export const userReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_SAVE_REQUEST:
      return { loading: false, selectedService: action.payload };
    case USER_SAVE_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_SAVE_FAIL:
      return { loading: false, error: action.payload };
    case USER_SELECTED_TIME_SLOT:
      return { ...state, timeSlot: action.payload };
    default:
      return state;
  }
};
