import {
  SERVICE_RETRIEVE_REQUEST,
  SERVICE_RETRIEVE_FAIL,
  SERVICE_RETRIEVE_SUCCESS,
  SERVICE_LAST_BOOKING_REQUEST,
  SERVICE_LAST_BOOKING_SUCCESS,
  SERVICE_LAST_BOOKING_FAIL
} from "../constants/serviceConstants";

export const serviceReducers = (state = { servicesList: [] }, action) => {
  switch (action.type) {
    case SERVICE_RETRIEVE_REQUEST:
      return { ...state, loading: true };
    case SERVICE_RETRIEVE_SUCCESS:
      return { loading: false, servicesList: action.payload };
    case SERVICE_RETRIEVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const serviceLastBookingReducers = (state = { servicesLastBooking: [] }, action) => {
  switch (action.type) {
    case SERVICE_LAST_BOOKING_REQUEST:
      return { ...state, loading: true };
    case SERVICE_LAST_BOOKING_SUCCESS:
      return { loading: false, servicesList: action.payload };
    case SERVICE_LAST_BOOKING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
