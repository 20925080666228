import * as Add2Calendar from "../dependency/add2calendar";
import "../dependency/add2calendar/css/add2calendar.css";
import { useEffect } from "react";
import moment from "moment";

const Add2CalendarComponent = ({ date, location, services }) => {
  const startTime = moment(new Date(date)).format("ddd, MMMM D, YYYY HH:mm");
  const endTime = moment(new Date(date)).add(services.duration, "minutes").format("ddd, MMMM D, YYYY HH:mm");

  useEffect(() => {
    const singleEvent = new Add2Calendar({
      title: location.businessName,
      start: startTime,
      end: endTime,
      location: location.googleGeoCode[0].formatted_address,
      description: services.serviceData,
    });
    singleEvent.createWidget("#single-normal");
  }, []);

  return <div id="single-normal" className="alignCalender"></div>;
};

export default Add2CalendarComponent;
