import {
  LOCATION_SEARCH_REQUEST,
  LOCATION_SEARCH_FAIL,
  LOCATION_SEARCH_SUCCESS,
  SELECTED_LOCATION_SAVE_SUCCESS,
  SELECTED_LOCATION_SAVE_REQUEST,
} from "../constants/locationConstants";
import axios from "axios";
import { axiosHeaders } from "../dependency/axiosHeader";


export const getLocationDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: LOCATION_SEARCH_REQUEST,
    });

    const { data: { response }} = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/locations`,
      {
        headers: axiosHeaders,
      }
    );
    dispatch({
      type: LOCATION_SEARCH_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const saveLocationDetails = (selectedLocation) => async (dispatch) => {
  try {
    dispatch({
      type: SELECTED_LOCATION_SAVE_REQUEST,
    });
    dispatch({
      type: SELECTED_LOCATION_SAVE_SUCCESS,
      payload: selectedLocation,
    });
  } catch (error) {
    console.log(error);
  }
};
