import {
    TEAM_MEMBERS_RETRIEVE_REQUEST,
    TEAM_MEMBERS_RETRIEVE_FAIL,
    TEAM_MEMBERS_RETRIEVE_SUCCESS,
    SELECTED_PROFESSIONAL_SAVE_REQUEST,
    SELECTED_PROFESSIONAL_SAVE_SUCCESS,
    SELECTED_PROFESSIONAL_SAVE_FAIL,
    SET_TEAMMEMBERNAME_REQUEST,
    SET_TEAMMEMBERNAME_SUCCESS,
    SET_TEAMMEMBERNAME_FAIL,
} from "../constants/teamConstants";

export const teamReducers = (state = { teamMembers: [] }, action) => {
    switch (action.type) {
        case TEAM_MEMBERS_RETRIEVE_REQUEST:
            return { ...state, loading: true };
        case TEAM_MEMBERS_RETRIEVE_SUCCESS:
            return { loading: false, teamMembers: action.payload };
        case TEAM_MEMBERS_RETRIEVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const selectedProfessionalReducers = (
    state = { selectedProfessional: "" },
    action
) => {
    switch (action.type) {
        case SELECTED_PROFESSIONAL_SAVE_REQUEST:
            return { ...state, loading: true };
        case SELECTED_PROFESSIONAL_SAVE_SUCCESS:
            return { loading: false, selectedProfessional: action.payload };
        default:
            return state;
    }
};

export const TeamNameAction = (state = { professionalName: "" }, action) => {
    switch (action.type) {
        case SET_TEAMMEMBERNAME_REQUEST:
            return { ...state, loading: true };
        case SET_TEAMMEMBERNAME_SUCCESS:
            return { loading: false, professionalName: action.payload };
        default:
            return state;
    }
};
