import {
  USER_SAVE_REQUEST,
  USER_SAVE_FAIL,
  USER_SAVE_SUCCESS,
  USER_SELECTED_TIME_SLOT,
} from "../constants/userConstants";


export const userSelectedTimeSlot = (timeSlot) => async (dispatch) => {
  dispatch({
    type: USER_SELECTED_TIME_SLOT,
    payload: timeSlot,
  });
};

export const saveUserDetailsAction =
  (user, selectedService) => async (dispatch) => {
    try {
      dispatch({
        type: USER_SAVE_REQUEST,
      });
      dispatch({
        type: USER_SAVE_SUCCESS,
        payload: { services: selectedService, userInfo: user },
      });
    } catch (error) {
      dispatch({
        type: USER_SAVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
