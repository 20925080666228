import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "./Header";
import store from "../store";

const AboutUs = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		store.dispatch({
			type: "RESET",
		});
		sessionStorage.clear();
	}, []);
	return (
		<div className="d-flex flex-column h-100">
			<Header></Header>
			<section className="h-100 d-flex flex-column mt-4 h-100 overflow-auto">
				<div className="container py-3">
					<div className="row">
						<div className="col-4">
							<div className="rounded w-100">
								<img src={require("../assets/images/Rectangle1.png")} alt="" className="w-100" />
							</div>
						</div>
						<div className="col-4">
							<div className="rounded w-100">
								<img src={require("../assets/images/Rectangle2.png")} alt="" className="w-100" />
							</div>
						</div>
						<div className="col-4">
							<div className="rounded w-100">
								<img src={require("../assets/images/Rectangle3.png")} alt="" className="w-100" />
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-sm-12 text-left">
							<h4>About Us</h4>
							<p className="text-muted h6">
								Welcome to Mens Only booking page Feel free to pre book or grab that first available appointment on the app. Mens Only is a
								family atmosphere where you will find Barbers and Stylists highly trained to give you the haircut and style you desire! Thank
								you for letting us mess with your head!
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default AboutUs;
